<div [formGroup]="numberForm">
  <label for="euros-{{ uniqueId }}"
    >{{ label }}<span class="has-text-danger" *ngIf="required">*</span></label
  >
  <div class="control has-icons-right">
    <input
      type="number"
      id="euros-{{ uniqueId }}"
      class="input form-control"
      [ngClass]="{ 'is-danger': showErrors && numberForm.invalid }"
      [placeholder]="placeholder || label + '...'"
      [step]="1"
      [min]="min"
      [max]="max"
      formControlName="number"
      (keyup)="onChange($event.target.value)"
      (change)="onChange($event.target.value)"
    />

    <span class="icon is-medium is-right"> € </span>

    <p class="help mb-1" *ngIf="helpText">{{ helpText }}</p>
  </div>
</div>
