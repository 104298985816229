<div>
  <div class="control">
    <label for="select-input-{{ uniqueId }}"
      >{{ label }}<span class="has-text-danger" *ngIf="required">*</span>
    </label>
    <p class="help mb-1" *ngIf="helpText">{{ helpText }}</p>
    <div class="control">
      <div
        class="select"
        [ngClass]="{ 'is-danger': showErrors && !value && required }"
      >
        <select
          id="select-input-{{ uniqueId }}"
          class="select"
          (change)="onSelect($event.target.value)"
          [attr.disabled]="readonly"
          #select
        >
          <option value="" selected disabled>
            {{ placeholder || 'Choose an option...' }}
          </option>
          <option
            [value]="option.value"
            [selected]="initialValue === option.value"
            [disabled]="option.disabled"
            *ngFor="let option of selectOptions"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>
