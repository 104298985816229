import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { environment } from '../../../../environments/environment'
import { CaseDetailComponent } from '../../../abacus/components/case-detail.component'
import { ActionType } from '../../../abacus/enums/action-type.enum'
import { Action } from '../../../abacus/interfaces/actions/action.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { PolicyInitiativeEditorialStatus } from '../../../common/enums/policy-initiative-editorial-status.enum'
import { policyInitiativeDefinition } from '../policy-initiative.definition'

@Component({
  selector: 'app-policy-initiative-detail',
  templateUrl: './policy-initiative-detail.component.html',
  styleUrls: ['./policy-initiative-detail.component.scss']
})
export class PolicyInitiativeDetailComponent
  extends CaseDetailComponent
  implements OnInit
{
  definition: ResourceDefinition = policyInitiativeDefinition

  publishAction: Action
  pendingAction: Action
  rejectAction: Action
  deleteAction: Action

  EditorialStatus = PolicyInitiativeEditorialStatus

  environment = environment

  constructor(
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute
  ) {
    super(
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit(): Promise<void> {
    await this.initDetailView()

    this.deleteAction = {
      type: ActionType.Delete,
      delete: {
        itemToDelete: this.item,
        definition: policyInitiativeDefinition,
        redirectTo: '/policy-initiatives'
      }
    }

    this.publishAction = {
      type: ActionType.Patch,
      patch: {
        path: `policy-initiatives/${this.item.id}/editorial-status`,
        body: { status: PolicyInitiativeEditorialStatus.Published },
        successMessage: 'The policy initiative has been published.',
        errorMessage: 'Error: Policy initiative could not be published'
      }
    }
    this.pendingAction = {
      type: ActionType.Patch,
      patch: {
        path: `policy-initiatives/${this.item.id}/editorial-status`,
        body: { status: PolicyInitiativeEditorialStatus.Pending },
        successMessage: 'The policy initiative has been switch to pending.',
        errorMessage: 'Error: Policy initiative could not be switch to pending'
      }
    }
    this.rejectAction = {
      type: ActionType.Patch,
      patch: {
        path: `policy-initiatives/${this.item.id}/editorial-status`,
        body: { status: PolicyInitiativeEditorialStatus.Rejected },
        successMessage: 'The policy initiative has been switch to rejected.',
        errorMessage: 'Error: Policy initiative could not be rejected'
      }
    }
  }
}
