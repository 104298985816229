import { Route } from '@angular/router'

import { ResourceMode } from '../../abacus/enums/resource-mode.enum'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { PermissionGuard } from '../../abacus/guards/permission.guard'

import { EngagementMechanismCreateEditComponent } from './engagement-mechanism-create-edit/engagement-mechanism-create-edit.component'
import { EngagementMechanismListComponent } from './engagement-mechanism-list/engagement-mechanism-list.component'

export const engagementMechanismRoutes: Route[] = [
  {
    path: 'engagement-mechanisms',
    component: EngagementMechanismListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browsePolicyInitiativeTaxonomies'
    }
  },
  {
    path: 'engagement-mechanisms/create',
    component: EngagementMechanismCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addPolicyInitiativeTaxonomies'
    }
  },
  {
    path: 'engagement-mechanisms/:id/edit',
    component: EngagementMechanismCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editPolicyInitiativeTaxonomies'
    }
  }
]
