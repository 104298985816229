import { Validators } from '@angular/forms'
import { InputType } from '../../../../abacus/enums/input-type.enum'
import { Field } from '../../../../abacus/interfaces/field.interface'
import { EvaluationBy } from '../../../../common/enums/policy-initiative/evaluation-by.enum'
import { PolicyInitiativeCreateEditComponent } from '../policy-initiative-create-edit.component'
import { InitiativeCategory } from '../../../../common/enums/policy-initiative/initiative-category.enum'
import {
  hideIsEvaluatedFields,
  showIsEvaluatedFields
} from './step-3-functions'

export const getStep3Fields = (
  context: PolicyInitiativeCreateEditComponent
): Field[] => {
  const isNotConcernedByEvaluation =
    context.item.category === InitiativeCategory.NationalGovernance ||
    context.item.category === InitiativeCategory.NationalGeneral

  return [
    {
      label: 'Has this initiative been evaluated?',
      property: 'isEvaluated',
      className: 'is-12',
      hidden: isNotConcernedByEvaluation,
      inputType: InputType.Select,
      selectOptions: [
        {
          label: 'Yes',
          value: 'true'
        },
        {
          label: 'No',
          value: 'false'
        }
      ],
      onChange: async (value: string) => {
        if (value === 'true') {
          showIsEvaluatedFields(context)
        } else {
          await hideIsEvaluatedFields(context)
        }
      }
    },
    {
      id: 'evaluationBy',
      property: 'evaluationBy',
      className: 'is-12',
      label: 'Who was the evaluation performed by?',
      required: true,
      hidden: true,
      inputType: InputType.Select,
      selectOptions: Object.keys(EvaluationBy).map((key) => ({
        label: EvaluationBy[key],
        value: EvaluationBy[key]
      }))
    },
    {
      id: 'isEvaluationResultsPubliclyAvailable',
      property: 'isEvaluationResultsPubliclyAvailable',
      className: 'is-12',
      label: 'Are the evaluation results publicly available?',
      hidden: true,
      inputType: InputType.Checkbox
    },
    {
      id: 'evaluationUrl',
      property: 'evaluationUrl',
      className: 'is-12',
      hidden: true,
      label: 'Evaluation URL',
      helpText:
        'If there is a page or file relevant to the evaluation (e.g., evaluation report, process, timeline), please add it here.',
      inputType: InputType.Text
    },
    {
      id: 'otherEvaluationUrl',
      property: 'otherEvaluationUrl',
      className: 'is-12',
      hidden: true,
      label: 'Evaluation URL 2',
      inputType: InputType.Text
    },
    {
      id: 'evaluationFiles',
      label: 'Evaluation file(s)',
      property: 'evaluationFiles',
      className: 'is-12',
      hidden: true,
      inputType: InputType.MultiFile,
      askFileYear: false,
      placeholder: 'Choose a file to upload'
    },
    {
      id: 'evaluationDescription',
      label: 'Evaluation description',
      helpText:
        '(e.g., scope, timeframe, findings/conclusion, recommendations)',
      property: 'evaluationDescription',
      className: 'is-12',
      hidden: true,
      inputType: InputType.Textarea,
      validators: [Validators.maxLength(1000)],
      placeholder: 'Choose a file to upload'
    }
  ]
}
