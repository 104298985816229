import { Yield } from '../../abacus/interfaces/yield.interface'

export const aiPrincipleYields: Yield[] = [
  {
    label: 'Name',
    property: 'name'
  },
  {
    label: 'type',
    property: 'type'
  }
]
