<div class="control">
  <label
    for="rich-text-input-{{ uniqueId }}"
    class="is-inline-flex is-align-items-center is-justify-content-center"
  >
    <span
      >{{ label }}<span class="has-text-danger" *ngIf="required">*</span></span
    >
    <i
      *ngIf="htmlTooltip"
      [appHtmlTooltip]="htmlTooltip"
      class="icon icon-info is-size-5 has-text-info ml-1"
    ></i>
  </label>

  <div
    class="content help mb-1"
    [innerHTML]="htmlHelpText"
    *ngIf="htmlHelpText"
  ></div>

  <!-- Example modal-->
  <div *ngIf="htmlExample">
    <p class="content is-small mb-2">
      <a (click)="showExampleModal = true">Click here</a> to see an example.
    </p>
    <div class="modal is-active" *ngIf="showExampleModal">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head pb-0">
          <p class="modal-card-title">{{ label }} example</p>
          <a
            class="is-flex is-align-item-center has-text-dark"
            aria-label="close"
            (click)="showExampleModal = false"
          >
            <i class="icon icon-x is-size-2"></i>
          </a>
        </header>
        <section class="modal-card-body is-size-5">
          <div class="columns">
            <div class="column" [innerHTML]="htmlExample"></div>
          </div>
        </section>
        <footer
          class="modal-card-foot pt-0 is-flex is-justify-content-flex-end"
        >
          <button class="button is-white" (click)="showExampleModal = false">
            Close
          </button>
        </footer>
      </div>
    </div>
  </div>

  <div
    class="input-wrapper"
    [ngClass]="{
      'is-danger':
        showErrors &&
        ((required && !content.length) || (max && content.length > max))
    }"
  >
    <ckeditor
      id="rich-text-input-{{ uniqueId }}"
      [editor]="editor"
      tagName="textarea"
      rows="15"
      [data]="content"
      [config]="editorConfig"
      (change)="onChange($event)"
    ></ckeditor>
  </div>

  <span
    class="content is-small"
    *ngIf="max"
    [ngClass]="{
      'has-text-danger': content.length > max
    }"
  >
    <span
      class="is-pulled-right has-tooltip has-tooltip-left"
      data-tooltip="The number of characters depends also on the formatting of the text."
    >
      {{ content.length || 0 }}/{{ max }}</span
    >
  </span>

  <p class="help mb-1" *ngIf="helpText">{{ helpText }}</p>
</div>
