<div [formGroup]="form">
  <div class="control">
    <label for="text-input-{{ uniqueId }}"
      >{{ label }}<span class="has-text-danger" *ngIf="required">*</span></label
    >

    <!-- Text array readonly inputs -->
    <div formArrayName="imageArray" class="mb-1">
      <div
        class="has-current-file is-flex is-align-items-center is-justify-content-space-between mt-1 has-background-white-ter pl-1"
        *ngFor="let imageControl of imageArray.controls; let i = index"
      >
        <case-image
          [path]="imageControl.value['path']"
          className="py-1 is-block"
        >
        </case-image>
        <span class="file-cta file-remove">
          <a (click)="removeImage(i)">
            <i class="icon icon-x is-size-5 has-text-danger"></i>
          </a>
        </span>
      </div>
    </div>

    <!-- New image input -->
    <div class="image">
      <div
        class="file"
        [ngClass]="{
          'show-errors': showErrors
        }"
      >
        <label class="image-label">
          <!-- Input element -->
          <input
            id="image-input-{{ uniqueId }}"
            class="file-input"
            type="file"
            accept="image/*"
            #imageInput
            [placeholder]="placeholder"
            (change)="addImage()"
          />
          <span class="file-cta" *ngIf="!loading">
            <span class="file-icon">
              <i class="icon icon-upload-cloud is-size-5"></i>
            </span>
            <span class="file-label">
              {{ placeholder }}
            </span>
          </span>

          <button class="button is-link is-loading full-width" *ngIf="loading">
            Loading
          </button>
        </label>
      </div>
    </div>

    <p class="help mb-1" *ngIf="helpText">{{ helpText }}</p>
  </div>
</div>
