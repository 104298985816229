<section>
  <div
    class="is-flex is-justify-content-space-between is-align-items-center mb-5"
  >
    <div class="left-part">
      <h1
        class="title is-2 has-text-weight-light"
        *ngIf="mode === ResourceMode.Create"
      >
        <span class="icon is-large">
          <i class="icon {{ definition.icon }} is-size-2 has-text-link"></i>
        </span>
        Add a {{ definition.nameSingular }}
      </h1>
      <h1
        class="title is-2 has-text-weight-light"
        *ngIf="mode === ResourceMode.Edit"
      >
        Update a {{ definition.nameSingular }}
      </h1>
    </div>
  </div>

  <app-step-progress-bar
    [stepCount]="4"
    [currentStep]="currentStep"
    [mode]="mode"
    (stepClicked)="goToStep($event)"
  ></app-step-progress-bar>

  <form [formGroup]="form" *ngIf="form" class="mb-huge">
    <div class="card p-4">
      <!-- Step 0 introduction -->
      <div class="columns">
        <div
          class="column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop is-6-fullhd is-offset-3-fullhd"
        >
          <app-introduction
            [step]="currentStep"
            [isNotConcernedByEvaluation]="
              item?.category === InitiativeCategory.NationalGovernance ||
              item?.category === InitiativeCategory.NationalGeneral
            "
          ></app-introduction>
        </div>
      </div>

      <!-- Fields -->
      <div class="columns">
        <div
          class="column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop is-6-fullhd is-offset-3-fullhd"
        >
          <div class="columns is-multiline is-align-items-flex-end">
            <ng-container *ngFor="let field of resolvedFields">
              <!-- Intergovernmental category intro text -->
              <div
                class="column is-12"
                *ngIf="
                  field.id === 'intergovernmentalOrganisation' && !field.hidden
                "
              >
                <div class="content is-normal">
                  <p>
                    You are about to submit a new intergovernmental or
                    supranational AI initiative. This form is intended for AI
                    initiatives led by intergovernmental organisations (e.g.,
                    the OECD, the United Nations, or the World Bank) or
                    supranational organisations (e.g., the European Union, the
                    African Union, the Arab League, ASEAN, or NATO).
                  </p>
                  <p>
                    This form is not intended for AI initiatives conducted
                    jointly by two or more countries or territories. For these
                    types of AI initiatives, please use the form for new
                    national AI initiative.
                  </p>
                  <p>
                    First, we're going to ask you several questions about this
                    initiative. The OECD.AI team and users very much look
                    forward to finding out about your efforts pertaining to AI.
                  </p>
                </div>
              </div>

              <ng-container *caseHasPermission="field.permission">
                <div
                  class="column is-flex is-align-items-flex-end {{
                    field.className || 'is-6'
                  }}"
                  [id]="field.id"
                  *ngIf="!field.hidden"
                >
                  <case-input
                    [type]="field.inputType"
                    [label]="field.label"
                    [placeholder]="field.placeholder"
                    [secondPlaceholder]="field.secondPlaceholder"
                    [initialValue]="field.initialValue"
                    [searchResources]="field.searchResources"
                    [filterResource]="field.filter"
                    [groupBy]="field.groupBy"
                    [resourceName]="definition.slug"
                    [searchParams]="field.searchParams"
                    [maxSelectedItems]="field.maxSelectedItems"
                    [selectOptions]="field.selectOptions"
                    [askFileYear]="field.askFileYear"
                    [min]="field.min"
                    [max]="field.max"
                    [showCreateButton]="field.showCreateButton"
                    [copyDateFromOnDateTo]="field.copyDateFromOnDateTo"
                    [readonly]="field.readonly"
                    [validators]="field.validators"
                    [helpText]="field.helpText"
                    [htmlHelpText]="field.htmlHelpText"
                    [htmlTooltip]="field.htmlTooltip"
                    [htmlExample]="field.htmlExample"
                    [showErrors]="showErrors"
                    (valueChanged)="onValueChanged($event, field)"
                  ></case-input>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- Navigation -->
      <div class="columns mt-2 mb-4">
        <div
          class="column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop is-6-fullhd is-offset-3-fullhd is-flex is-align-items-flex-end is-justify-content-space-between"
        >
          <a
            (click)="goToStep(currentStep - 1)"
            class="button mr-auto is-white"
            [ngClass]="{ 'is-loading': loading }"
            *ngIf="currentStep > 0"
            >Previous</a
          >

          <a
            [routerLink]="['/policy-initiatives', 'create']"
            [queryParams]="{ step: 1 }"
            class="button is-link ml-auto"
            [ngClass]="{ 'is-loading': loading }"
            *ngIf="currentStep === 0"
            >Get started</a
          >

          <a
            (click)="
              saveAndGoToStep({
                step: currentStep
              })
            "
            class="button is-link-light mr-6"
            [ngClass]="{ 'is-loading': loading }"
            *ngIf="currentStep > 0"
            >Save</a
          >
          <a
            (click)="
              saveAndGoToStep({
                step: currentStep + 1
              })
            "
            class="button is-link"
            [ngClass]="{ 'is-loading': loading }"
            *ngIf="currentStep > 0 && currentStep < 4"
            >Save and next</a
          >

          <a
            (click)="submit()"
            class="button is-link"
            [ngClass]="{ 'is-loading': loading }"
            *ngIf="currentStep === 4"
            >Submit</a
          >
        </div>
      </div>
    </div>
  </form>
</section>
