<div [formGroup]="form">
  <div class="control">
    <label for="text-input-{{ uniqueId }}"
      >{{ label }}<span class="has-text-danger" *ngIf="required">*</span></label
    >

    <!-- Text array readonly inputs -->
    <div formArrayName="textArray">
      <div
        class="columns"
        *ngFor="let textControl of textArray.controls; let i = index"
      >
        <div class="column is-10">
          <input
            type="text"
            class="input form-control"
            [formControlName]="i"
            readonly
          />
        </div>
        <div class="column">
          <button class="button is-warning" (click)="removeText(i)">
            Remove
          </button>
        </div>
      </div>
    </div>

    <!-- New text input -->
    <div class="columns" *ngIf="!max || textArray.length < max">
      <div class="column is-10">
        <input
          type="text"
          class="input form-control"
          [ngClass]="{ 'is-danger': showErrors && form.invalid }"
          [placeholder]="placeholder || label + '...'"
          formControlName="text"
          id="text-input-{{ uniqueId }}"
          #input
        />
      </div>
      <div class="column">
        <button
          class="button is-link"
          (click)="addText()"
          [disabled]="!input.value"
        >
          Add
        </button>
      </div>
    </div>

    <p class="help mb-1" *ngIf="helpText">{{ helpText }}</p>
  </div>
</div>
