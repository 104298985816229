<ng-container [ngSwitch]="type">
  <case-text-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Text"
  ></case-text-input>

  <case-textarea-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [htmlHelpText]="htmlHelpText"
    [htmlTooltip]="htmlTooltip"
    [htmlExample]="htmlExample"
    [max]="max"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Textarea"
  ></case-textarea-input>

  <case-checkbox-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Checkbox"
  ></case-checkbox-input>

  <case-color-picker-input
    [label]="label"
    [uniqueId]="uniqueId"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.ColorPicker"
  ></case-color-picker-input>

  <case-datepicker-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Datepicker"
  ></case-datepicker-input>

  <case-email-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    placeholder="Custom placeholder for email input..."
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Email"
  ></case-email-input>

  <case-multi-search-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [resources]="searchResources"
    [filter]="filterResource"
    [groupBy]="groupBy"
    [params]="searchParams"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [htmlHelpText]="htmlHelpText"
    [showCreateButton]="showCreateButton"
    [maxSelectedItems]="maxSelectedItems"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.MultiSearch"
  ></case-multi-search-input>

  <case-multi-select-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [selectOptions]="selectOptions"
    itemNameSingular="element"
    itemNamePlural="elements"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [maxSelectedItems]="maxSelectedItems"
    [readonly]="readonly"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.MultiSelect"
  >
  </case-multi-select-input>

  <case-number-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [min]="min"
    [max]="max"
    [step]="step"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Number"
  ></case-number-input>

  <case-euros-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [min]="min"
    [max]="max"
    [step]="step"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Euros"
  ></case-euros-input>

  <case-time-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Time"
  ></case-time-input>

  <case-rich-text-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [htmlHelpText]="htmlHelpText"
    [htmlTooltip]="htmlTooltip"
    [htmlExample]="htmlExample"
    [max]="max"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.RichText"
  ></case-rich-text-input>

  <case-select-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [selectOptions]="selectOptions"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [required]="required"
    [readonly]="readonly"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Select"
  ></case-select-input>

  <case-radio-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [selectOptions]="selectOptions"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Radio"
  ></case-radio-input>

  <case-tel-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Tel"
  ></case-tel-input>

  <case-password-input
    [label]="label"
    [uniqueId]="uniqueId"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Password"
  ></case-password-input>

  <case-toggle-input
    [label]="label"
    [uniqueId]="uniqueId"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Toggle"
  ></case-toggle-input>

  <case-file-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [accept]="accept"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [resourceName]="resourceName"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.File"
  >
  </case-file-input>

  <case-image-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [resourceName]="resourceName"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Image"
  >
  </case-image-input>

  <case-image-gallery-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [resourceName]="resourceName"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.ImageGallery"
  >
  </case-image-gallery-input>

  <case-multi-text-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [max]="max"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.MultiText"
  ></case-multi-text-input>

  <case-multi-image-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [resourceName]="resourceName"
    [helpText]="helpText"
    [max]="max"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.MultiImage"
  ></case-multi-image-input>

  <case-multi-file-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [askFileYear]="askFileYear"
    [helpText]="helpText"
    [resourceName]="resourceName"
    [max]="max"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.MultiFile"
  ></case-multi-file-input>

  <case-multi-checkbox-input
    [label]="label"
    [uniqueId]="uniqueId"
    [initialValue]="initialValue"
    [selectOptions]="selectOptions"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [htmlHelpText]="htmlHelpText"
    [readonly]="readonly"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.MultiCheckbox"
  >
  </case-multi-checkbox-input>
</ng-container>
