import { ActionType } from '../../abacus/enums/action-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'
import { PolicyInitiativeEditorialStatus } from '../../common/enums/policy-initiative-editorial-status.enum'

export const policyInitiativeDefinition: ResourceDefinition = {
  title: 'Policy initiatives',
  nameSingular: 'policy initiative',
  namePlural: 'policy initiatives',
  className: 'PolicyInitiative',
  mainIdentifier: 'englishName',
  slug: 'policy-initiatives',
  path: 'policy-initiatives',
  icon: 'icon-layers',
  hasDetailPage: true,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.DETAIL,
  childrenThatPreventDelete: [],
  actionButtons: [
    {
      label: 'Publish',
      className: 'button is-success p-x-lg is-small',
      permission: 'editTools',
      condition: (policyInitiative) =>
        policyInitiative.editorialStatus ===
        PolicyInitiativeEditorialStatus.Pending,
      action: (policyInitiative) => ({
        type: ActionType.Patch,
        patch: {
          path: `policy-initiatives/${policyInitiative.id}/editorial-status`,
          body: { status: PolicyInitiativeEditorialStatus.Published },
          successMessage: 'The policy initiative has been published',
          errorMessage: 'Error: policy initiative could not be published',
          redirectTo: `policy-initiatives/${policyInitiative.id}`
        }
      })
    },
    {
      label: 'Reject',
      className: 'button is-danger ml-2 p-x-lg is-small',
      condition: (policyInitiative) =>
        policyInitiative.editorialStatus ===
        PolicyInitiativeEditorialStatus.Pending,
      action: (policyInitiative) => ({
        type: ActionType.Patch,
        patch: {
          path: `policy-initiatives/${policyInitiative.id}/editorial-status`,
          body: { status: PolicyInitiativeEditorialStatus.Rejected },
          successMessage: 'The policy initiative has been rejected',
          errorMessage: 'Error: policy initiative could not be rejected',
          redirectTo: `policy-initiatives/${policyInitiative.id}`
        }
      })
    }
  ],
  dropdownLinks: [
    {
      label: 'Edit',
      permission: 'editPolicyInitiatives',
      action: (policyInitiative) => ({
        type: ActionType.Link,
        link: {
          path: `${policyInitiativeDefinition.path}/${policyInitiative.id}/edit`
        }
      })
    },
    {
      label: 'Delete',
      permission: 'deleteOwnPolicyInitiatives',
      action: (policyInitiative) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: policyInitiative,
          definition: policyInitiativeDefinition
        }
      })
    }
  ]
}
