<div [formGroup]="form">
  <div class="control">
    <label
      for="textarea-input-{{ uniqueId }}"
      class="is-inline-flex is-align-items-center is-justify-content-center"
    >
      <span
        >{{ label }}<span class="has-text-danger" *ngIf="required">*</span>
      </span>

      <i
        *ngIf="htmlTooltip"
        [appHtmlTooltip]="htmlTooltip"
        class="icon icon-info is-size-5 has-text-info ml-1"
      ></i>
    </label>

    <div
      class="content help mb-1"
      [innerHTML]="htmlHelpText"
      *ngIf="htmlHelpText"
    ></div>

    <!-- Example modal-->
    <div *ngIf="htmlExample">
      <p class="content is-small mb-2">
        <a (click)="showExampleModal = true">Click here</a> to see an example.
      </p>
      <div class="modal is-active" *ngIf="showExampleModal">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head pb-0">
            <p class="modal-card-title">{{ label }} example</p>
            <a
              class="is-flex is-align-item-center has-text-dark"
              aria-label="close"
              (click)="showExampleModal = false"
            >
              <i class="icon icon-x is-size-2"></i>
            </a>
          </header>
          <section class="modal-card-body is-size-5">
            <div class="columns">
              <div class="column" [innerHTML]="htmlExample"></div>
            </div>
          </section>
          <footer
            class="modal-card-foot pt-0 is-flex is-justify-content-flex-end"
          >
            <button class="button is-link" (click)="showExampleModal = false">
              Close
            </button>
          </footer>
        </div>
      </div>
    </div>

    <textarea
      name="textarea"
      cols="3"
      rows="7"
      class="input form-control textarea"
      [ngClass]="{ 'is-danger': showErrors && form.invalid }"
      [placeholder]="placeholder || label + '...'"
      id="text-input-{{ uniqueId }}"
      (keyup)="onChange($event.target.value)"
      formControlName="textarea"
    ></textarea>

    <span
      class="content is-small"
      *ngIf="max"
      [ngClass]="{
        'has-text-danger': form.get('textarea').value?.length > max
      }"
    >
      <span class="is-pulled-right"
        >{{ form.get('textarea').value?.length || 0 }}/{{ max }}</span
      >
    </span>

    <p class="help mb-1" *ngIf="helpText">{{ helpText }}</p>
  </div>
</div>
