<div class="multisearch-box">
  <div class="control">
    <label for="multi-search-input-{{ uniqueId }}"
      >{{ label }}<span class="has-text-danger" *ngIf="required">*</span></label
    >
    <p class="help mb-1" *ngIf="helpText">{{ helpText }}</p>

    <div
      class="content help mb-1"
      [innerHTML]="htmlHelpText"
      *ngIf="htmlHelpText"
    ></div>

    <div
      *ngIf="selectedSearchResults.length"
      class="field is-grouped is-grouped-multiline"
    >
      <div
        class="control"
        *ngFor="let selectedSearchResult of selectedSearchResults"
      >
        <div class="tags has-addons">
          <span class="tag is-link"
            ><i
              class="icon pr-5 pl-3 {{
                getResourceIcon(selectedSearchResult.resourceName)
              }}"
            ></i
            >{{ selectedSearchResult.label }}</span
          >
          <a
            class="tag is-delete has-background-link-light"
            (click)="toggleItem(selectedSearchResult)"
            *ngIf="!readonly"
          ></a>
        </div>
      </div>
    </div>
    <div class="is-flex is is-align-items-flex-start">
      <div class="is-fullwidth">
        <div class="multisearch-input-field">
          <input
            id="multi-search-input-{{ uniqueId }}"
            type="text"
            class="input"
            (keyup)="onSearchInputKeyup($event)"
            [placeholder]="
              selectedSearchResults.length ? '' : placeholder || label + '...'
            "
            (focus)="showList = true; focusedItemIndex = 0"
            [disabled]="
              readonly ||
              (maxSelectedItems &&
                selectedSearchResults.length >= maxSelectedItems)
            "
            [ngClass]="{
              'is-danger':
                showErrors && required && !selectedSearchResults.length
            }"
            autocomplete="off"
            #searchInput
          />

          <!-- Filters -->
          <div *ngIf="filter" class="filter-section">
            <span class="content is-small mr-2"
              >Filter by {{ filter.nameSingular }}:</span
            >

            <ng-container *ngFor="let filterItem of filterItems">
              <span
                class="tag pointer mr-2 mb-2 is-rounded is-outlined"
                [ngClass]="{ 'is-link': filterItem.selected }"
                (click)="toggleFilterItem(filterItem)"
                >{{ filterItem.label }}</span
              >&nbsp;
            </ng-container>
          </div>

          <ul
            class="box list"
            *ngIf="showList && suggestedSearchResults.length"
          >
            <li
              *ngIf="activeFilterItem && !isAllSelected()"
              class="pointer"
              (click)="selectAll()"
            >
              Select all results from&nbsp;
              <strong>{{ activeFilterItem.label }}</strong>
            </li>
            <li
              *ngIf="activeFilterItem && isAllSelected()"
              class="pointer"
              (click)="unselectAll()"
            >
              Unselect all results from&nbsp;
              <strong>{{ activeFilterItem.label }}</strong>
            </li>
            <li
              *ngFor="let searchResult of suggestedSearchResults; let i = index"
              class="pointer"
              [ngClass]="{
                'is-active': isSelected(searchResult),
                'is-focused': focusedItemIndex === i,
                pointer: searchResult.resourceName !== 'groupHeader',
                'group-header': searchResult.resourceName === 'groupHeader'
              }"
              (click)="toggleItem(searchResult)"
            >
              <!-- Icon-->
              <i
                class="icon is-size-5 {{
                  getResourceIcon(searchResult.resourceName)
                }}"
              ></i>
              <span innerHTML="{{ searchResult.label }}"></span>
            </li>
          </ul>
        </div>
      </div>
      <a
        (click)="showCreateModal = true"
        class="button is-link is-light ml-2"
        *ngIf="showCreateButton"
      >
        <i class="icon icon-plus is-size-4"></i>
      </a>
    </div>
  </div>
</div>

<div class="modal is-active" *ngIf="showCreateModal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head pb-0">
      <p class="modal-card-title">Add a {{ resources[0].nameSingular }}</p>
      <a
        class="is-flex is-align-item-center has-text-dark"
        aria-label="close"
        (click)="showCreateModal = false"
      >
        <i class="icon icon-x is-size-2"></i>
      </a>
    </header>
    <section class="modal-card-body is-size-5">
      <div class="columns">
        <div class="column">
          <div [formGroup]="createForm">
            <div class="control">
              <label for="new-item"
                >Name<span class="has-text-danger" *ngIf="required"
                  >*</span
                ></label
              >
              <input
                type="text"
                class="input form-control"
                [ngClass]="{ 'is-danger': showErrors && createForm.invalid }"
                (keypress)="onCreateInputKeyUp($event)"
                [placeholder]="'Name...'"
                formControlName="name"
                id="new-item"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer
      class="modal-card-foot pt-0 is-flex is-justify-content-space-between"
    >
      <button class="button is-white" (click)="showCreateModal = false">
        Cancel
      </button>
      <button class="button is-link" (click)="submitCreate()">Save</button>
    </footer>
  </div>
</div>
