import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { CaseCreateEditComponent } from '../../../abacus/components/case-create-edit.component'
import { InputType } from '../../../abacus/enums/input-type.enum'
import { Field } from '../../../abacus/interfaces/field.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { caseCreateEditTemplate } from '../../../abacus/templates/case-create-edit.template'

import { IntergovernmentalOrganisationType } from '../../../common/enums/intergovernmental-organisation-type.enum'
import { nationalOrganisationDefinition } from '../../national-organisation/national-organisation.definition'
import { policyInitiativeDefinition } from '../../policy-initiative/policy-initiative.definition'
import { intergovernmentalOrganisationDefinition } from '../intergovernmental-organisation.definition'

@Component({ template: caseCreateEditTemplate })
export class IntergovernmentalOrganisationCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = intergovernmentalOrganisationDefinition
  fields: Field[] = [
    {
      label: 'Name',
      placeholder: 'Enter the name...',
      property: 'name',
      className: 'is-6',
      inputType: InputType.Text,
      required: true
    },
    {
      label: 'Description',
      placeholder: 'Enter the description...',
      property: 'description',
      className: 'is-6',
      inputType: InputType.Text,
      required: true
    },
    {
      label: 'Type',
      placeholder: 'Enter the type...',
      property: 'type',
      className: 'is-6',
      inputType: InputType.Select,
      required: true,
      selectOptions: [
        {
          label: 'Intergovernmental',
          value: IntergovernmentalOrganisationType.Intergovernmental
        },
        {
          label: 'Supranational',
          value: IntergovernmentalOrganisationType.Supranational
        }
      ]
    },
    {
      label: 'Year Founded',
      placeholder: 'Enter the year founded...',
      property: 'yearFounded',
      className: 'is-6',
      inputType: InputType.Number,
      required: true
    },
    {
      label: 'Website',
      placeholder: 'Enter the website...',
      property: 'website',
      className: 'is-6',
      inputType: InputType.Text
    },
    {
      label: 'Countries',
      properties: {
        nationalOrganisationIds: 'nationalOrganisationIds'
      },
      retrievedItemProperties: {
        nationalOrganisationIds: 'nationalOrganisationIds'
      },
      className: 'is-6',
      searchResources: [nationalOrganisationDefinition],
      inputType: InputType.MultiSearch,
      required: true
    },
    {
      label: 'Policy Initiatives',
      properties: {
        policyInitiativeIds: 'policyInitiativeIds'
      },
      retrievedItemProperties: {
        policyInitiativeIds: 'policyInitiativeIds'
      },
      className: 'is-6',
      searchResources: [policyInitiativeDefinition],
      inputType: InputType.MultiSearch
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
