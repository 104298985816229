import { MenuItem } from './abacus/interfaces/menu-item.interface'

export const menuItems: MenuItem[] = [
  {
    label: 'Tools Catalogue',
    icon: 'icon-book-open',
    permissionsOr: [
      'browseMetricUseCases',
      'browseOwnMetricUseCases',
      'browseMetrics',
      'browseOwnMetrics',
      'browseToolUseCases',
      'browseOwnToolUseCases',
      'browseTools',
      'browseOwnTools'
    ],
    items: [
      {
        label: 'Tools',
        permissionsOr: ['browseTools', 'browseOwnTools'],
        routePath: '/tools'
      },
      {
        label: 'Tool use cases',
        permissionsOr: ['browseToolUseCases', 'browseOwnToolUseCases'],
        routePath: '/tool-use-cases'
      },

      {
        label: 'Metrics',
        permissionsOr: ['browseMetrics', 'browseOwnMetrics'],
        routePath: '/metrics'
      },
      {
        label: 'Metric use cases',
        permissionsOr: ['browseMetricUseCases', 'browseOwnMetricUseCases'],
        routePath: '/metric-use-cases'
      }
    ]
  },
  {
    label: 'Catalogue Taxonomies',
    icon: 'icon-grid',
    permissionsOr: ['browseTaxonomies'],
    items: [
      {
        label: 'Approaches',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/approaches'
      },
      {
        label: 'Benefits',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/benefits'
      },
      {
        label: 'Core application areas',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/core-application-areas'
      },
      {
        label: 'Countries',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/countries'
      },
      {
        label: 'Enforcements',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/enforcements'
      },
      {
        label: 'Geographical scopes',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/geographical-scopes'
      },
      {
        label: 'Impacted stakeholders',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/impacted-stakeholders'
      },
      {
        label: 'Licenses',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/licenses'
      },
      {
        label: 'Lifecycles',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/lifecycles'
      },
      {
        label: 'Maturities',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/maturities'
      },
      {
        label: 'Objectives',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/objectives'
      },
      {
        label: 'Organisations',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/organisations'
      },
      {
        label: 'People involved',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/people-involveds'
      },
      {
        label: 'Programming languages',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/programming-languages'
      },
      {
        label: 'Purposes',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/purposes'
      },
      {
        label: 'Required skills',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/required-skills'
      },
      {
        label: 'Risk Management Stages',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/risk-management-stages'
      },
      {
        label: 'Stakeholder groups',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/stakeholder-groups'
      },
      {
        label: 'Tags',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/tags'
      },
      {
        label: 'Target groups',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/target-groups'
      },
      {
        label: 'Target sectors',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/target-sectors'
      },
      {
        label: 'Target users',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/target-users'
      },
      {
        label: 'Technology platforms',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/technology-platforms'
      },
      {
        label: 'Tool types',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/tool-types'
      },
      {
        label: 'Metric types',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/metric-types'
      },
      {
        label: 'Usage rights',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/usage-rights'
      },
      {
        label: 'Validities',
        permissionsOr: ['browseTaxonomies'],
        routePath: '/validities'
      }
    ]
  },
  {
    label: 'AI National Policies',
    icon: 'icon-compass',
    permissionsOr: ['browsePolicyInitiatives', 'browseOwnPolicyInitiatives'],
    items: [
      {
        label: 'Policy Initiatives',
        permissionsOr: [
          'browsePolicyInitiatives',
          'browseOwnPolicyInitiatives'
        ],
        routePath: '/policy-initiatives'
      }
    ]
  },
  {
    label: 'Policy Initiative Taxonomies',
    icon: 'icon-grid',
    permissionsOr: ['browsePolicyInitiativeTaxonomies'],
    items: [
      {
        label: 'National organisations',
        permissionsOr: ['browsePolicyInitiativeTaxonomies'],
        routePath: '/national-organisations'
      },
      {
        label: 'Intergovernmental organisations',
        permissionsOr: ['browsePolicyInitiativeTaxonomies'],
        routePath: '/intergovernmental-organisations'
      },
      {
        label: 'Initiative types',
        permissionsOr: ['browsePolicyInitiativeTaxonomies'],
        routePath: '/initiative-types'
      },
      {
        label: 'Engagement mechanisms',
        permissionsOr: ['browsePolicyInitiativeTaxonomies'],
        routePath: '/engagement-mechanisms'
      },
      {
        label: 'AI Principles',
        permissionsOr: ['browsePolicyInitiativeTaxonomies'],
        routePath: '/ai-principles'
      },
      {
        label: 'AI Tags',
        permissionsOr: ['browsePolicyInitiativeTaxonomies'],
        routePath: '/ai-tags'
      },
      {
        label: 'Target sectors',
        permissionsOr: ['browsePolicyInitiativeTaxonomies'],
        routePath: '/target-sectors'
      }
    ]
  },
  {
    label: 'Users',
    permissionsOr: ['browseUsers', 'browseRoles'],
    icon: 'icon-user',
    items: [
      {
        label: 'Users',
        permissionsOr: ['browseUsers'],
        routePath: '/users'
      },
      {
        label: 'Roles',
        permissionsOr: ['browseRoles'],
        routePath: '/roles'
      }
    ]
  }
]
