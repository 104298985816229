import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core'
import { ValidatorFn, Validators } from '@angular/forms'

import { CaseInput } from '../../../interfaces/case-input.interface'

@Component({
  selector: 'case-datepicker-input',
  template: `<div class="control">
    <label id="datepicker-input-{{ uniqueId }}"
      >{{ label }}<span class="has-text-danger" *ngIf="required">*</span></label
    >

    <input class="input" type="date" (change)="onChange($event)" #input />
    <p class="help mb-1" *ngIf="helpText">{{ helpText }}</p>
  </div>`
})
export class DatepickerInputComponent implements CaseInput, OnChanges {
  @ViewChild('dp') dp: any

  @Input() label: string
  @Input() helpText: string
  // Accepts YYYY-MM-DD formatted date
  @Input() initialValue: string
  @Input() placeholder: string
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string

  @Output() valueChanged: EventEmitter<string> = new EventEmitter()

  @ViewChild('input', { static: true }) input: ElementRef

  today = Date.now()
  required?: boolean

  ngOnChanges(): void {
    if (this.initialValue !== undefined) {
      this.input.nativeElement.value = this.initialValue
    }

    this.required = this.validators.includes(Validators.required)
  }

  onChange(event: any) {
    this.valueChanged.emit(event.target.value)
  }
}
