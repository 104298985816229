<div class="multiselect-box">
  <div class="control">
    <label (click)="showList = true"
      >{{ label }}
      <span class="has-text-danger" *ngIf="required">*</span>
    </label>
    <p class="help mb-1" *ngIf="helpText">{{ helpText }}</p>
    <div
      class="content help mb-1"
      [innerHTML]="htmlHelpText"
      *ngIf="htmlHelpText"
    ></div>

    <div class="columns">
      <div class="column" *ngFor="let group of optionGroups">
        <div class="group-title">
          <strong>{{ group.name }}</strong>
        </div>
        <div class="group-options">
          <div class="control my-4" *ngFor="let option of group.options">
            <label
              class="checkbox"
              for=""
              (click)="toggleSelected(option)"
              [ngClass]="{ 'is-checked': option.selected }"
              >{{ option.label }}
              <span class="has-text-danger" *ngIf="required">*</span>
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
