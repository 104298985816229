import { InputType } from '../../../../abacus/enums/input-type.enum'
import { Field } from '../../../../abacus/interfaces/field.interface'
import { InitiativeCategory } from '../../../../common/enums/policy-initiative/initiative-category.enum'
import { policyInitiativeDefinition } from '../../policy-initiative.definition'
import { PolicyInitiativeCreateEditComponent } from '../policy-initiative-create-edit.component'

export const getStep2Fields = (
  context: PolicyInitiativeCreateEditComponent
): Field[] => {
  const fields: Field[] = [
    {
      id: 'relatedIntergovernmentalInitiatives',
      label: 'Related intergovernmental and/or supranational initiatives',
      htmlHelpText: `Please select which intergovernmental and supranational initiative(s) are directly associated with the one you are adding, if any. 
      
      If there is a relevant intergovernmental or supranational initiative that is not listed, please let us know at <a mailto="ai@oecd.org">ai@oecd.org</a>.  
      `,
      className: 'is-12',
      properties: {
        policyInitiativeIds: 'relatedIntergovernmentalPolicyInitiativeIds'
      },
      retrievedItemProperties: {
        relatedIntergovernmentalPolicyInitiativeIds:
          'relatedIntergovernmentalPolicyInitiativeIds'
      },
      groupBy: 'intergovernmentalOrganisation',
      inputType: InputType.MultiSearch,
      showCreateButton: false,
      searchResources: [policyInitiativeDefinition]
    }
  ]

  if (context.item.category !== InitiativeCategory.Intergovernmental) {
    fields.push({
      id: 'relatedNationalInitiatives',
      label: 'Related national initiatives',
      htmlHelpText: `Often times, initiatives fall under the broader umbrella of an overarching national AI strategy or are associated with another initiative. For instance, an initiative to implement one or more commitments from a national AI strategy. 
    The list below consists of all of the AI initiatives contained in OECD.AI for [country]. Please select which initiative(s) are associated with the one you are addingn if any. 
    If there is a related initiative that is not listed, you may add it using the links below.
        <ul>
        <li>Add a new AI governance body or mechanism</li>
    <li>Add a new AI policy initiative, regulation, guideline, standard, programme or project</li>
    </ul>
    `,
      className: 'is-12 tmp-is-background-multiple',
      properties: {
        policyInitiativeIds: 'relatedNationalPolicyInitiativeIds'
      },
      retrievedItemProperties: {
        relatedNationalPolicyInitiativeIds: 'relatedNationalPolicyInitiativeIds'
      },
      groupBy: 'nationalOrganisation',
      inputType: InputType.MultiSearch,
      showCreateButton: false,
      searchResources: [policyInitiativeDefinition]
    })
  }

  return fields
}
