<div class="control is-fullwidth">
  <label for="text-input-{{ uniqueId }}">{{ label }}</label>

  <p class="help mb-1" *ngIf="helpText">{{ helpText }}</p>

  <!-- Uploaded files -->
  <div class="mb-1">
    <div
      class="has-current-file is-flex is-justify-content-space-between is-align-items-center mt-1 has-background-white-ter pl-1"
      *ngFor="let file of files; let i = index"
    >
      <a
        class="is-ellipsis"
        [href]="storagePath + '/' + file.path"
        target="_blank"
      >
        {{ file.name }}
      </a>
      <span class="file-cta file-remove">
        <a (click)="removeFile(i)">
          <i class="icon icon-x is-size-5 has-text-danger"></i>
        </a>
      </span>
    </div>
  </div>

  <a class="button is-info" (click)="openForm()">Add a file</a>
</div>

<div class="modal is-active" *ngIf="showModal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <div class="modal-card p-a-0">
      <header class="modal-card-head">
        <p class="modal-card-title">Add a file</p>
      </header>
      <section class="modal-card-body py-0">
        <form [formGroup]="form">
          <div class="columns is-multiline">
            <div class="column is-12">
              <div class="control is-fullwidth">
                <label for="text-input-name">File name</label>
                <input
                  type="text"
                  class="input form-control"
                  formControlName="name"
                  id="text-input-name"
                />
              </div>
            </div>

            <div class="column is-12">
              <ng-container *ngIf="askFileYear">
                <div class="control is-fullwidth">
                  <label for="text-input-year">File year</label>
                  <p class="help mb-1">Indicate the parution year</p>
                  <input
                    type="number"
                    step="1"
                    class="input form-control"
                    formControlName="year"
                    id="text-input-year"
                  />
                </div>
              </ng-container>
            </div>
            <div class="column is-12">
              <!-- New file input -->
              <div class="control is-fullwidth">
                <div class="columns">
                  <div class="column">
                    <div
                      class="file mt-4 pl-0 is-flex is-align-items-center file-wrapper"
                    >
                      <label for="file-input-{{ uniqueId }} " class="mb-0 mr-2"
                        >File <span class="has-text-danger">*</span></label
                      >
                      <div
                        class="file"
                        [ngClass]="{
                          'show-errors': showErrors
                        }"
                      >
                        <label class="file-label">
                          <!-- Input element -->
                          <input
                            id="file-input-{{ uniqueId }}"
                            class="file-input"
                            type="file"
                            accept="pdf"
                            #fileInput
                            [placeholder]="placeholder"
                            (change)="addFile()"
                          />
                          <span class="file-cta" *ngIf="!loading">
                            <span class="file-icon">
                              <i class="icon icon-upload-cloud is-size-5"></i>
                            </span>
                            <span class="file-label">
                              {{ form.value.path || placeholder }}
                            </span>
                          </span>

                          <button
                            class="button is-link is-loading full-width"
                            *ngIf="loading"
                          >
                            Loading
                          </button>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <div
          class="is-flex is-flex-wrap-wrap is-align-items-flex-start is-justify-content-space-between is-fullwidth"
        >
          <button (click)="closeForm()" class="button is-white-ter p-x-lg">
            Cancel
          </button>
          <button
            (click)="saveForm()"
            class="button is-link"
            [disabled]="form.invalid"
          >
            Save
          </button>
        </div>
      </footer>
    </div>
  </div>
</div>
