<label for="image-gallery-input-{{ uniqueId }}" class="full-width">
  {{ label }}
  <span class="has-text-danger" *ngIf="required">*</span>
</label>
<div
  class="is-flex is-flex-wrap-wrap is-align-items-center is-justify-content-flex-start is-fullwidth"
>
  <div class="control mr-2">
    <div class="image" *ngIf="!selectedImage">
      <div
        class="file"
        [ngClass]="{
          'show-errors': showErrors,
          'is-invalid': !selectedImage
        }"
      >
        <label class="image-label">
          <!-- Input element -->
          <input
            id="image-input-{{ uniqueId }}"
            class="file-input"
            type="file"
            accept="image/*"
            #imageInput
            [placeholder]="placeholder"
            (change)="imageInputEvent($event)"
          />
          <span class="file-cta mr-2" *ngIf="!loading">
            <span class="file-icon">
              <i class="icon icon-upload-cloud is-size-5"></i>
            </span>
            <span class="file-label">
              {{ placeholder || 'Choose an image' }}
            </span>
          </span>

          <button class="button is-link is-loading full-width" *ngIf="loading">
            Loading
          </button>
        </label>
      </div>
    </div>

    <!-- Previously uploaded image or selected image -->
    <div
      *ngIf="selectedImage"
      class="has-current-file is-flex is-align-items-center is-justify-content-space-between mt-1 has-background-white-ter pl-1"
    >
      <case-image [path]="selectedImage" className="py-1 is-block">
      </case-image>
      <span class="file-cta file-remove">
        <a (click)="clear()">
          <i class="icon icon-x is-size-5 has-text-danger"></i>
        </a>
      </span>
    </div>
  </div>

  <a
    (click)="showImageGalleryModal = !showImageGalleryModal"
    (keydown)="showImageGalleryModal = !showImageGalleryModal"
    tabindex="0"
    class="button is-ghost is-small m-t-md"
  >
    <span class="icon">
      <i class="icon icon-image"></i>
    </span>
    <span> Or pick an image from the gallery</span>
  </a>
</div>
<p class="help mb-1" *ngIf="helpText">{{ helpText }}</p>

<div class="modal is-large is-active" *ngIf="showImageGalleryModal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <div class="modal-card p-a-0">
      <header class="modal-card-head">
        <p class="modal-card-title">Pick an image from our library</p>
        <button (click)="clear()" class="delete"></button>
      </header>
      <section class="modal-card-body">
        <div
          class="is-flex is-flex-wrap-wrap is-align-items-flex-start is-justify-content-flex-start"
        >
          <div
            class="control m-3"
            *ngFor="let imagePath of images; let i = index"
          >
            <label class="radio-input pl-0 pb-0 mt-0">
              <input
                type="radio"
                name="image-picker"
                [value]="imagePath"
                (change)="selectImage(imagePath)"
              />
              <span class="checkmark checkmark-img"></span>
              <i
                class="icon icon-check-circle is-size-4 has-text-white mr-1 mb-1"
              ></i>
              <img
                [src]="storagePath + '/' + imagePath + '-videoCard.jpg'"
                alt="Default image {{ i }}"
              />
            </label>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div
          class="is-flex is-flex-wrap-wrap is-align-items-flex-start is-justify-content-space-between is-fullwidth"
        >
          <button (click)="clear()" class="button is-white-ter p-x-lg">
            Cancel
          </button>
          <button
            class="button is-link"
            (click)="confirmSelection()"
            [disabled]="!selectedImage"
          >
            Confirm selection
          </button>
        </div>
      </footer>
    </div>
  </div>
</div>
