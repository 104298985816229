import { Component, OnInit } from '@angular/core'
import { FormGroup, UntypedFormBuilder } from '@angular/forms'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { CaseCreateEditComponent } from '../../../abacus/components/case-create-edit.component'
import { Field } from '../../../abacus/interfaces/field.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { policyInitiativeDefinition } from '../policy-initiative.definition'
import { getStep1Fields } from './fields/step-1-fields'
import { getStep2Fields } from './fields/step-2-fields'
import { getStep3Fields } from './fields/step-3-fields'
import { step4Fields } from './fields/step-4-fields'
import { ResourceMode } from '../../../abacus/enums/resource-mode.enum'
import { setStep1ConditionalForm } from './fields/step-1-functions'
import { setStep3ConditionalForm } from './fields/step-3-functions'
import { InitiativeCategory } from '../../../common/enums/policy-initiative/initiative-category.enum'

@Component({
  templateUrl: './policy-initiative-create-edit.component.html',
  styleUrls: ['./policy-initiative-create-edit.component.scss']
})
export class PolicyInitiativeCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = policyInitiativeDefinition
  loading = false
  currentStep: number = 0
  InitiativeCategory = InitiativeCategory

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private componentActivatedRoute: ActivatedRoute,
    private componentRouter: Router,
    private componentFlashMessageService: FlashMessageService,
    public componentResourceService: ResourceService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.componentActivatedRoute.queryParams.subscribe(
      async (params: Params) => {
        this.showErrors = false
        this.currentStep = params.step ? parseInt(params.step, 10) : 0

        this.mode =
          this.componentActivatedRoute?.snapshot?.data?.mode ||
          ResourceMode.Create

        if (this.mode === ResourceMode.Create && this.currentStep > 1) {
          this.componentFlashMessageService.error('Error: Invalid step.')
          this.componentRouter.navigate([], {
            queryParams: { step: null },
            queryParamsHandling: 'merge'
          })
        }
        if (this.mode === ResourceMode.Edit && !this.currentStep) {
          this.currentStep = 1
        }

        if (this.mode === ResourceMode.Edit) {
          this.item = await this.componentResourceService
            .show(
              this.definition.slug,
              this.componentActivatedRoute?.snapshot?.params?.id
            )
            .then((itemRes) => itemRes)
            .catch(() => {
              this.componentFlashMessageService.error(
                'Error: Policy initiative not found.'
              )
              this.componentRouter.navigate(['/404'])
            })
        }

        switch (this.currentStep) {
          case 0:
            this.fields = []
            break
          case 1:
            this.fields = getStep1Fields(this)
            break
          case 2:
            this.fields = getStep2Fields(this)
            break
          case 3:
            this.fields = getStep3Fields(this)
            break
          case 4:
            this.fields = step4Fields
            break
        }

        this.resolvedFields = await this.resolveFields(this.fields)
        this.form = await this.generateForm(this.resolvedFields)

        this.setBreadcrumbs()

        // Set the form conditional fields based on the existing values.
        if (this.mode === ResourceMode.Edit) {
          if (this.currentStep === 1) {
            setStep1ConditionalForm(this, this.item)
          } else if (this.currentStep === 3) {
            await setStep3ConditionalForm(this, this.item)
          }
        }
      }
    )
  }

  /**
   * Save the form as draft and go to a defined step. Validate the fields of the current step before proceeding.
   * Once the draft is saved, we will force an EDIT mode to retrieve other step's fields.
   *
   * @param step the step to go to.
   * @param skipPristine if true, the pristine fields will be skipped (useful for going back to a previous step).
   *
   * @returns void
   */
  async saveAndGoToStep({
    step,
    skipPristineValidation
  }: {
    step: number
    skipPristineValidation?: boolean
  }): Promise<void> {
    const stepInvalidFields: Field[] = this.getInvalidFields({
      fields: this.fields,
      form: this.form,
      skipPristineValidation
    })

    if (stepInvalidFields.length) {
      this.showErrors = true
      this.componentFlashMessageService.error(
        'Some fields are invalid. Please correct them before proceeding: ' +
          stepInvalidFields.map((field: Field) => field.label).join(', ')
      )

      // Scroll to top.
      const firstErrorField = document.querySelector('h1')
      if (firstErrorField) {
        // Smooth scroll to the element
        firstErrorField.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }

      return
    }

    this.item = await this.save()

    this.componentRouter.navigate(
      ['/policy-initiatives', this.item.id, 'edit'],
      {
        queryParams: { step },
        queryParamsHandling: 'merge'
      }
    )
  }

  goToStep(step: number): void {
    this.componentRouter.navigate(
      ['/policy-initiatives', this.item.id, 'edit'],
      {
        queryParams: { step },
        queryParamsHandling: 'merge'
      }
    )
  }

  /**
   * Check if the fields are valid for a given set of fields.
   *
   * @param fields the fields to check.
   * @param form the form to check the fields against.
   * @param skipPristineValidation if true, the pristine fields will be skipped (useful for going back to a previous step).
   *
   * @returns the invalid fields.
   */
  getInvalidFields({
    fields,
    form,
    skipPristineValidation
  }: {
    fields: Field[]
    form: FormGroup
    skipPristineValidation?: boolean
  }): Field[] {
    if (skipPristineValidation) {
      return fields.filter(
        (field: Field) =>
          form.get(field.property).invalid && !form.get(field.property).pristine
      )
    }

    return fields.filter((field: Field) => {
      const fieldProperties: string[] = field.property
        ? [field.property]
        : Object.keys(field.properties)

      return fieldProperties.some(
        (property: string) => form.get(property)?.invalid
      )
    })
  }

  async save(): Promise<{ id: number }> {
    this.loading = true

    if (this.mode === ResourceMode.Create) {
      return this.componentResourceService
        .store(this.definition.slug, this.form.value)
        .then((res) => {
          this.loading = false
          this.componentFlashMessageService.success(
            'Policy initiative has been saved'
          )
          return res
        })
        .catch((error) => {
          this.loading = false
          this.componentFlashMessageService.error(
            'Failed to save policy initiative'
          )
        })
    } else {
      return this.componentResourceService
        .patch(
          `${this.definition.slug}/${this.item.id}/step-${this.currentStep}`,
          this.form.value
        )
        .then((res) => {
          this.loading = false
          this.componentFlashMessageService.success(
            'Policy initiative has been saved'
          )
          return this.item
        })
        .catch((error) => {
          this.loading = false
          this.componentFlashMessageService.error(
            'Failed to save policy initiative'
          )
        })
    }
  }

  async submit() {
    this.loading = true
    // Patch current data
    await this.componentResourceService.patch(
      `${this.definition.slug}/${this.item.id}/step-${this.currentStep}`,
      this.form.value
    )

    await this.componentResourceService
      .patch(`${this.definition.slug}/${this.item.id}/submit`)
      .then(() => {
        this.loading = false
        this.componentFlashMessageService.success(
          'The policy initiative has been submitted'
        )

        this.componentRouter.navigate(['/policy-initiatives'])
      })
      .catch(() => {
        this.loading = false
        this.componentFlashMessageService.error(
          'Failed to submit policy initiative'
        )
      })
  }
}
