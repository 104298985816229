<div class="modal is-active" *ngIf="showModal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head pb-0">
      <p class="modal-card-title">{{ title }}</p>
      <a
        class="is-flex is-align-item-center has-text-dark"
        aria-label="close"
        (click)="close()"
      >
        <i class="icon icon-x is-size-2"></i>
      </a>
    </header>

    <section class="modal-card-body">
      <p class="mb-4 is-size-5" *ngIf="helpText">{{ helpText }}</p>
      <table class="table is-fullwidth is-bordered is-narrow" *ngIf="keyPoints">
        <tr *ngFor="let keyPoint of keyPoints">
          <td>{{ keyPoint.label }}</td>
          <td>{{ keyPoint.value }}</td>
        </tr>
      </table>

      <form [formGroup]="form" *ngIf="form" class="full-width">
        <div class="columns is-multiline is-align-items-flex-end">
          <ng-container *ngFor="let field of resolvedFields">
            <ng-container *caseHasPermission="field.permission">
              <div
                class="column  {{ field.className || 'is-6' }}"
                *ngIf="!field.hidden"
              >
                <case-input
                  [type]="field.inputType"
                  [label]="field.label"
                  [placeholder]="field.placeholder"
                  [secondPlaceholder]="field.secondPlaceholder"
                  [initialValue]="field.initialValue"
                  [searchResources]="field.searchResources"
                  [resourceName]="definition.slug"
                  [searchParams]="field.searchParams"
                  [maxSelectedItems]="field.maxSelectedItems"
                  [selectOptions]="field.selectOptions"
                  [min]="field.min"
                  [max]="field.max"
                  [copyDateFromOnDateTo]="field.copyDateFromOnDateTo"
                  [readonly]="field.readonly"
                  [validators]="field.validators"
                  [helpText]="field.helpText"
                  [showErrors]="showErrors"
                  (valueChanged)="onValueChanged($event, field)"
                ></case-input>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </form>
    </section>
    <footer
      class="modal-card-foot pt-0 is-flex is-justify-content-space-between"
    >
      <button class="button is-white" (click)="close()">Cancel</button>
      <button
        class="button is-link"
        (click)="submit()"
        [ngClass]="{ 'is-loading': loading }"
      >
        Save
      </button>
      <button
        class="button is-link"
        (click)="submit()"
        [ngClass]="{ 'is-loading': loading }"
      >
        <i class="icon icon-save"></i>
      </button>
    </footer>
  </div>
</div>
