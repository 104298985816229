<ng-container *ngIf="item">
  <!-- Main container -->
  <nav class="level">
    <!-- Left side -->
    <div class="level-left">
      <div class="level-item">
        <h1 class="title is-2 has-text-weight-light" [title]="item.name">
          {{ item.englishName | truncate : ['50'] }}
        </h1>
        <span
          class="tag is-warning ml-2"
          *ngIf="item.editorialStatus === EditorialStatus.Pending"
          >Pending</span
        >
        <span
          class="tag is-black ml-2"
          *ngIf="item.editorialStatus === EditorialStatus.Rejected"
          >Rejected</span
        >
        <span
          class="tag is-success ml-2"
          *ngIf="item.editorialStatus === EditorialStatus.Published"
          >Published</span
        >
        <span
          class="tag is-disabled ml-2"
          *ngIf="item.editorialStatus === EditorialStatus.Draft"
          >Draft</span
        >
      </div>
    </div>

    <!-- Right side -->
    <div class="level-right">
      <p class="level-item">
        <a class="button is-danger is-outlined" [caseAction]="deleteAction">
          <span class="icon is-small">
            <i aria-hidden="true" class="icon icon-delete"></i>
          </span>
          <span>Delete</span>
        </a>
      </p>
      <p class="level-item">
        <a
          *ngIf="item.editorialStatus === EditorialStatus.Pending"
          class="button is-success"
          [caseAction]="publishAction"
        >
          <span class="icon is-small">
            <i aria-hidden="true" class="icon icon-file-text"></i>
          </span>
          <span>Publish</span>
        </a>
        <a
          *ngIf="
            item.editorialStatus === EditorialStatus.Published ||
            item.editorialStatus === EditorialStatus.Rejected
          "
          class="button is-warning"
          [caseAction]="pendingAction"
          >Switch to pending</a
        >
      </p>
      <p
        class="level-item"
        *ngIf="item.editorialStatus === EditorialStatus.Pending"
      >
        <a class="button is-danger" [caseAction]="rejectAction">
          <span class="icon is-small">
            <i aria-hidden="true" class="icon icon-file-text"></i>
          </span>
          <span>Reject</span>
        </a>
      </p>
      <p class="level-item">
        <a
          class="button is-link"
          [routerLink]="['/policy-initiatives/' + item.id + '/edit']"
        >
          <span class="icon is-small">
            <i aria-hidden="true" class="icon icon-edit"></i>
          </span>
          <span> Edit </span>
        </a>
      </p>
    </div>
  </nav>

  <div class="columns is-multiline">
    <div class="column is-8">
      <div class="card">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                English name
              </p>
              <p class="is-size-6">{{ item.englishName }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Name in original language
              </p>
              <p class="is-size-6">{{ item.originalName ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Description
              </p>
              <p class="is-size-6">{{ item.description ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                National Organisation
              </p>
              <p class="is-size-6">
                {{ item.nationalOrganisation?.name ?? '--' }}
              </p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Intergovernmental Organisation
              </p>
              <p class="is-size-6">
                {{ item.intergovernmentalOrganisation?.name ?? '--' }}
              </p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Responsible Organisation
              </p>
              <p class="is-size-6">
                {{ item.responsibleOrganisation ?? '--' }}
              </p>
            </div>

            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Category
              </p>
              <p class="is-size-6">{{ item.category ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Extent Binding
              </p>
              <p class="is-size-6">{{ item.extentBinding ?? '--' }}</p>
            </div>

            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Joint
              </p>
              <p class="is-size-6">{{ item.joint }}</p>
            </div>

            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Start Year
              </p>
              <p class="is-size-6">{{ item.startYear ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                End Year
              </p>
              <p class="is-size-6">{{ item.endYear ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Overview
              </p>
              <p class="is-size-6">{{ item.overview ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Budget
              </p>
              <p class="is-size-6">{{ item.budget ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Evaluation By
              </p>
              <p class="is-size-6">{{ item.evaluationBy }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Evaluation Results Is Publicly Available
              </p>
              <p class="is-size-6">
                {{ item.evaluationResultsIsPubliclyAvailable ?? '--' }}
              </p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Evaluation Description
              </p>
              <p class="is-size-6">{{ item.evaluationDescription ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Evaluation URL
              </p>
              <p class="is-size-6" *ngIf="item.evaluationUrl">
                <a [href]="item.evaluationUrl" target="_blank">
                  {{ item.evaluationUrl }}
                </a>
              </p>
              <p class="is-size-6" *ngIf="!item.evaluationUrl">--</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Other Evaluation URL
              </p>
              <p class="is-size-6" *ngIf="item.otherEvaluationUrl">
                <a [href]="item.otherEvaluationUrl" target="_blank">
                  {{ item.otherEvaluationUrl }}
                </a>
              </p>
              <p class="is-size-6" *ngIf="!item.otherEvaluationUrl">--</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Evaluation Files
              </p>
              <ul *ngIf="item.evaluationFiles?.length">
                <li *ngFor="let file of item.evaluationFiles">
                  <a
                    [href]="environment.storagePath + '/' + file.path"
                    target="_blank"
                    download
                    >{{ file.name }}</a
                  >
                </li>
              </ul>
              <p *ngIf="!item.evaluationFiles?.length" class="is-size-6">--</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Relevant Files
              </p>
              <ul *ngIf="item.relevantFiles?.length">
                <li *ngFor="let file of item.relevantFiles">
                  <a
                    [href]="environment.storagePath + '/' + file.path"
                    target="_blank"
                    download
                    >{{ file.name }}</a
                  >
                </li>
              </ul>
              <p *ngIf="!item.relevantFiles?.length" class="is-size-6">--</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Video URL
              </p>
              <p class="is-size-6" *ngIf="item.videoUrl">
                <a [href]="item.videoUrl" target="_blank"
                  >{{ item.videoUrl }}
                </a>
              </p>
              <p class="is-size-6" *ngIf="!item.videoUrl">--</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                More Infos
              </p>
              <p class="is-size-6">{{ item.moreInfos ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Action Plan
              </p>
              <p class="is-size-6">{{ item.actionPlan ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Engagement Description
              </p>
              <p class="is-size-6">{{ item.engagementDescription ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Monitoring Mechanism
              </p>
              <p class="is-size-6">{{ item.hasMonitoringMechanism ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Monitoring Mechanism Description
              </p>
              <p class="is-size-6">
                {{ item.monitoringMechanismDescription ?? '--' }}
              </p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Website
              </p>
              <p class="is-size-6" *ngIf="item.website">
                <a [href]="item.website" target="_blank">
                  {{ item.website }}
                </a>
              </p>
              <p class="is-size-6" *ngIf="!item.website">--</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Status
              </p>
              <p class="is-size-6">{{ item.status ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Responsible Organisation SI
              </p>
              <p class="is-size-6">
                {{ item.responsibleOrganisationSI ?? '--' }}
              </p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Responsible Organisation SI Location
              </p>
              <p class="is-size-6">
                {{ item.responsibleOrganisationSILocation ?? '--' }}
              </p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Responsible Organisation SD Location
              </p>
              <p class="is-size-6">
                {{ item.responsibleOrganisationSDLocation ?? '--' }}
              </p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Budget Available
              </p>
              <p class="is-size-6">{{ item.budgetAvailable ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Is Evaluated
              </p>
              <p class="is-size-6">{{ item.isEvaluated ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Other Evaluation By
              </p>
              <p class="is-size-6">{{ item.otherEvaluationBy ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Relevant URLs
              </p>
              <ul *ngIf="item.relevantUrls?.length">
                <li *ngFor="let url of item.relevantUrls">
                  <a [href]="url" target="_blank"> {{ url ?? '--' }}</a>
                </li>
              </ul>
              <p *ngIf="!item.relevantUrls?.length" class="is-size-6">--</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Trustworthy AI Relation
              </p>
              <p class="is-size-6">{{ item.trustworthyAIRelation ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Intergovernmental Coordination
              </p>
              <p class="is-size-6">
                {{ item.intergovernmentalCoordination ?? '--' }}
              </p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Trustworthy AI Mechanism Description
              </p>
              <p class="is-size-6">
                {{ item.trustworthyAIMechanismDescription ?? '--' }}
              </p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Other Engagement Mechanism
              </p>
              <p class="is-size-6">
                {{ item.otherEngagementMechanism ?? '--' }}
              </p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Other Initiative Type
              </p>
              <p class="is-size-6">{{ item.otherInitiativeType ?? '--' }}</p>
            </div>

            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Target Sectors
              </p>
              <ul *ngIf="item.targetSectors?.length">
                <li *ngFor="let sector of item.targetSectors">
                  {{ sector.name }}
                </li>
              </ul>
              <p *ngIf="!item.targetSectors?.length" class="is-size-6">--</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Principles
              </p>
              <ul *ngIf="item.principles?.length">
                <li *ngFor="let principle of item.principles">
                  {{ principle.name }}
                </li>
              </ul>
              <p *ngIf="!item.principles?.length" class="is-size-6">--</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Tags
              </p>
              <ul *ngIf="item.tags?.length">
                <li *ngFor="let tag of item.tags">{{ tag.name }}</li>
              </ul>
              <p *ngIf="!item.tags?.length" class="is-size-6">--</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Initiative Types
              </p>
              <ul *ngIf="item.initiativeTypes?.length">
                <li *ngFor="let type of item.initiativeTypes">
                  {{ type.name }}
                </li>
              </ul>
              <p *ngIf="!item.initiativeTypes?.length" class="is-size-6">--</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Joint Countries
              </p>
              <ul *ngIf="item.jointCountries?.length">
                <li *ngFor="let country of item.jointCountries">
                  {{ country.name }}
                </li>
              </ul>
              <p *ngIf="!item.jointCountries?.length" class="is-size-6">--</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Participating Countries
              </p>
              <ul *ngIf="item.participatingCountries?.length">
                <li *ngFor="let country of item.participatingCountries">
                  {{ country.name }}
                </li>
              </ul>
              <p *ngIf="!item.participatingCountries?.length" class="is-size-6">
                --
              </p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Participating Organisations
              </p>
              <ul *ngIf="item.participatingOrganisations?.length">
                <li *ngFor="let org of item.participatingOrganisations">
                  {{ org.name }}
                </li>
              </ul>
              <p
                *ngIf="!item.participatingOrganisations?.length"
                class="is-size-6"
              >
                --
              </p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Related Policy Initiatives
              </p>
              <ul *ngIf="item.relatedPolicyInitiatives?.length">
                <li *ngFor="let initiative of item.relatedPolicyInitiatives">
                  {{ initiative.englishName }}
                </li>
              </ul>
              <p
                *ngIf="!item.relatedPolicyInitiatives?.length"
                class="is-size-6"
              >
                --
              </p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Engagement Mechanisms
              </p>
              <ul *ngIf="item.engagementMechanisms?.length">
                <li *ngFor="let mechanism of item.engagementMechanisms">
                  {{ mechanism.name }}
                </li>
              </ul>
              <p *ngIf="!item.engagementMechanisms?.length" class="is-size-6">
                --
              </p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Images
              </p>
              <ul *ngIf="item.images?.length">
                <li *ngFor="let image of item.images">
                  <case-image [path]="image.path" className="py-1 is-block">
                  </case-image>
                </li>
              </ul>
              <p *ngIf="!item.images?.length" class="is-size-6">--</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Source Files
              </p>
              <ul *ngIf="item.sourceFiles?.length">
                <li *ngFor="let file of item.sourceFiles">
                  <a
                    [href]="environment.storagePath + '/' + file.path"
                    target="_blank"
                    download
                    >{{ file.name }}</a
                  >
                </li>
              </ul>
              <p *ngIf="!item.sourceFiles?.length" class="is-size-6">--</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Created By
              </p>
              <p class="is-size-6">{{ item.createdBy?.name ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Last Updated By
              </p>
              <p class="is-size-6">{{ item.lastUpdatedBy?.name ?? '--' }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Created At
              </p>
              <p class="is-size-6">{{ item.createdAt | date }}</p>
            </div>
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Updated At
              </p>
              <p class="is-size-6">{{ item.updatedAt | date }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
