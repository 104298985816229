import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-introduction',
  template: `
    <!-- Step 0 -->
    <div class="content is-normal pt-5" *ngIf="!step">
      <h1 class="title is-2">Before you begin</h1>
      <p>Thank you for providing details on new or updated AI initiatives.</p>

      <h2 class="title is-5">
        Before continuing with your submission, you may wish to:
      </h2>
      <ul>
        <li>Download the full list of questions in Word (.doc) format.</li>
        <li>
          View sample initiatives from the Global AI Public Policies &
          Initiatives Registry
        </li>
      </ul>

      <h2 class="title is-5">During the survey</h2>
      <p>
        Once you begin the submission process, you'll be asked to enter or
        review: categories:
      </p>
      <ul>
        <li>
          Whether the initiative best fits one of the following categories:
          <ul>
            <li>National AI strategy</li>
            <li>AI governance bodies and mechanisms</li>
            <li>
              AI policy initiatives, regulations, guidelines, standards and
              programmes or projects
            </li>
          </ul>
        </li>

        <li>
          A description and details about the initiative, such as its name,
          status, budget (depending on the type of initiative), and the theme(s)
          it relates to, among others.
        </li>
        <li>Related AI initiatives.</li>
        <li>Links, relevant files, and other supporting material(s).</li>

        <p>
          Please remember to save your submission from time to time as you
          progress through the survey. You will find initiatives for %%
          [country/org] that have already been saved and/or published on the
          Global AI Public Policies & Initiatives Registry's administrative
          dashboard.
        </p>
      </ul>
    </div>

    <!-- Step 2 -->
    <div class="content is-normal pt-5" *ngIf="step === 2">
      <h1 class="title is-2">Associating other relevant initiatives</h1>
      <h6>Related intergovernmental and/or supranational initiatives</h6>
      <p>
        You are adding an overarching national AI strategy. Sometimes, such
        strategies serve as a means to implement intergovernmental or
        supranational agreements (e.g., the OECD AI Principles, the Council of
        Europe Convention on AI (CAI), the EU AI Act, etc.). 
      </p>

      <p>
        The list below consists of all of the intergovernmental and
        supranational AI initiatives that [country] appears to be involved in.
        Please select which initiative(s) are directly associated with the one
        you are adding, if any.
      </p>

      <p>
        If there is a relevant intergovernmental or supranational initiative
        that is not listed, please let us know at ai@oecd.org.
      </p>

      <h6>Related national initiatives</h6>
      <p>
        You are adding an overarching national AI strategy. Often times, such
        strategies serve as an umbrella for a series of more specific, granular
        initiatives.
      </p>

      <p>
        The list below consists of all of the AI initiatives contained in
        OECD.AI for [country]. Please select which initiative(s) fall within the
        one you are adding, if any. For instance, if another AI initiative is
        being carried out because it is included in the national AI strategy,
        please select it.
      </p>
      <p>
        If there is a related initiative that is not listed, you may add it
        using the links below.
      </p>
      <ul>
        <li>Add a new AI governance body or mechanism</li>
        <li>
          Add a new AI policy initiative, regulation, guideline, standard,
          programme or project
        </li>
      </ul>
    </div>

    <!-- Step 3 -->
    <div class="content is-normal pt-5" *ngIf="step === 3">
      <h1 class="title is-2">Initiative evaluation(s)</h1>

      <!-- Special case: Step 3 with no fields -->
      <div class="columns" *ngIf="isNotConcernedByEvaluation">
        <div
          class="column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop is-6-fullhd is-offset-3-fullhd"
        >
          <div class="message is-primary">
            <div class="message-body">
              ℹ️ As your initiative is not concerned by evaluation, you can go
              directly to the 5th step.
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!isNotConcernedByEvaluation">
        <p>
          Evaluations involve the systematic and objective assessment of an
          ongoing or completed initiative, its design, implementation and
          results.
        </p>

        <p>
          Details about the extent to which AI initiatives have been, or will
          be, evaluated is of significant importance to researchers, as well as
          other governments who seek to learn and take inspiration from what has
          worked -- and what has not -- in other governments. Evaluations are
          also important tools for public transparency and accountability.
        </p>

        <p>
          Please add details related to evaluations that have been, or are
          planned to be, conducted with regards to the initiative. Such
          evaluations could be done in-house or independently (e.g., using a
          consulting firm, a national auditing institution, a bilateral aid
          agency (e.g., AFD, GIZ, IDRC, SIDA), an international organisation
          like the OECD, EU, IDB etc.).
        </p>
      </ng-container>
    </div>

    <!-- Step 4 -->
    <div class="content is-normal pt-5" *ngIf="step === 4">
      <h1 class="title is-2">Links and relevant material(s)</h1>
      <p>
        On this page, you will be asked to provide URLs and other relevant
        materials, such as images, PDFs and other files, related to the
        initiative.
      </p>

      <p>
        These items are critical to help policymakers and researchers learn more
        about your AI initiative, and to help improve the accuracy of the
        OECD.AI Policy Observatory's AI-assisted tool, AI Recap. Please do not
        upload any materials that you do not wish to be made public on OECD.AI.
      </p>
    </div>
  `
})
export class IntroductionComponent {
  @Input() step: number
  @Input() isNotConcernedByEvaluation: boolean
}
