import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChange
} from '@angular/core'
import { ValidatorFn, Validators } from '@angular/forms'

import { CaseInput } from '../../../interfaces/case-input.interface'
import { SelectOption } from '../../../interfaces/select-option.interface'

@Component({
  selector: 'case-multi-checkbox-input',
  templateUrl: './multi-checkbox-input.component.html',
  styleUrls: ['./multi-checkbox-input.component.scss']
})
export class MultiCheckboxInputComponent implements CaseInput, OnChanges {
  @Input() label: string
  @Input() initialValue: string[] = []
  @Input() selectOptions: SelectOption[]
  @Input() helpText: string
  @Input() htmlHelpText?: string
  @Input() readonly: boolean
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string

  @Output() valueChanged: EventEmitter<string[]> = new EventEmitter()

  activeOptions: string[] = []
  showList = false
  required: boolean

  optionGroups: { name: string; options: SelectOption[] }[] = []

  constructor(private elementRef: ElementRef, private ngZone: NgZone) {}

  ngOnChanges(changes: { selectOptions?: SimpleChange }) {
    this.required = this.validators.includes(Validators.required)

    // Reset form value if we change select options.
    if (this.activeOptions.length && changes.selectOptions) {
      return (this.activeOptions = [])
    }

    this.activeOptions = this.initialValue || []

    if (Array.isArray(this.activeOptions)) {
      this.activeOptions = this.activeOptions.map((option) => String(option))
    } else {
      this.activeOptions = [String(this.activeOptions)]
    }

    if (this.selectOptions?.length) {
      this.selectOptions.forEach((selectOption: SelectOption) => {
        if (
          this.activeOptions.find(
            (activeOption) =>
              String(activeOption) === String(selectOption.value)
          )
        ) {
          selectOption.selected = true
        }
      })
    }

    // Create groups based on subLabel.
    this.optionGroups = this.selectOptions.reduce((acc, option) => {
      const group = acc.find((group) => group.name === option.subLabel)
      if (group) {
        group.options.push(option)
      } else {
        acc.push({ name: option.subLabel, options: [option] })
      }
      return acc
    }, [])
  }

  toggleSelected(option: SelectOption): void {
    if (this.readonly) {
      return
    }

    const clickedOption: string = String(option.value)

    this.ngZone.run(() => {
      const index = this.activeOptions.indexOf(clickedOption)
      if (index !== -1) {
        option.selected = !option.selected
        this.activeOptions.splice(index, 1)
      } else {
        option.selected = !option.selected
        this.activeOptions.push(clickedOption)
      }

      this.valueChanged.emit(this.activeOptions)
    })
  }
}
