import { InputType } from '../../../../abacus/enums/input-type.enum'
import { Field } from '../../../../abacus/interfaces/field.interface'

export const step4Fields: Field[] = [
  {
    label: 'Official PDF',
    property: 'sourceFiles',
    helpText:
      'If your initiative has an official PDF document associated with it, please upload it here. You may upload versions in English as well as the original language.',
    className: 'is-12',
    inputType: InputType.MultiFile,
    askFileYear: true,
    placeholder: 'Choose a file to upload...'
  },
  {
    label: 'Other Relevant URL',
    property: 'relevantUrls',
    className: 'is-12',
    inputType: InputType.MultiText,
    max: 10
  },
  {
    label: 'Image',
    helpText:
      'Please upload any relevant images or photos relevant for to initiative',
    property: 'images',
    className: 'is-12',
    inputType: InputType.MultiImage,
    placeholder: 'Choose an image to upload...',
    max: 10
  },
  {
    label: 'Other relevant files',
    property: 'relevantFiles',
    helpText:
      'Please upload any other relevant files (e.g., action plans, roadmaps, presentations etc.)',
    className: 'is-12',
    inputType: InputType.MultiFile,
    askFileYear: true,
    placeholder: 'Choose a file to upload...'
  },
  {
    id: 'videoUrl',
    label: 'Video',
    helpText:
      'You may add a URL to a hosted video (e.g., On YouTube or Vimeo) to be embedded on the initiative’s page on OECD.AI.',
    className: 'is-12',
    inputType: InputType.Text,
    property: 'videoUrl'
  },
  {
    id: 'moreInfos',
    label: 'Anything else',
    helpText:
      'Would you like to share any other information about the initiative?',
    className: 'is-12',
    inputType: InputType.Textarea,
    property: 'moreInfos'
  }
]
