<label
  for="image-input-{{ uniqueId }}"
  class="full-width"
  [ngClass]="{
    'pb-0': helpText,
    'has-text-danger': showErrors && isEmpty() && required
  }"
>
  {{ label }}
  <span class="has-text-danger" *ngIf="required">*</span>
</label>

<p class="help mb-4 mt-0" *ngIf="helpText">{{ helpText }}</p>
<p
  class="help mb-4 mt-0 has-text-danger"
  *ngIf="showErrors && isEmpty() && required"
>
  Please select an option.
</p>

<div class="columns is-multiline">
  <div
    class="column is-12-mobile"
    *ngFor="let option of selectOptions"
    (click)="select(option)"
  >
    <div class="control is-fullheight">
      <label
        class="radio is-boxed radio-label is-align-items-flex-start mt-0 p-4"
        [ngClass]="{
          'is-selected': option.selected,
          'is-disabled': option.disabled,
          'is-error': showErrors && isEmpty() && required
        }"
      >
        <span class="radio-cta">
          <span
            class="radio-label has-text-black"
            [ngClass]="{
              'has-text-grey': option.disabled
            }"
          >
            {{ option.label }}
          </span>
          <span
            class="radio-sub-label has-text-grey-dark mt-2"
            [ngClass]="{
              'has-text-grey': option.disabled
            }"
          >
            {{ option.subLabel }}
          </span>
        </span>
      </label>
    </div>
  </div>
</div>
