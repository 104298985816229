import { Validators } from '@angular/forms'
import { InitiativeCategory } from '../../../../common/enums/policy-initiative/initiative-category.enum'
import { InitiativeStatus } from '../../../../common/enums/policy-initiative/initiative-status.enum'
import { InputType } from '../../../../abacus/enums/input-type.enum'
import { Field } from '../../../../abacus/interfaces/field.interface'
import { aiTagDefinition } from '../../../ai-tag/ai-tag.definition'
import { PolicyInitiativeCreateEditComponent } from '../policy-initiative-create-edit.component'
import { nationalOrganisationDefinition } from '../../../national-organisation/national-organisation.definition'
import { intergovernmentalOrganisationDefinition } from '../../../intergovernmental-organisation/intergovernmental-organisation.definition'
import { ResponsibleOrganisationLocation } from '../../../../common/enums/policy-initiative/responsible-organisation-location.enum'
import {
  showInterGovernmentalForm,
  showNationalForm,
  showNationalGeneralForm,
  showNationalGovernanceForm,
  showNationalStrategyForm
} from './step-1-functions'
import moment from 'moment'
import { targetSectorDefinition } from '../../../target-sector/target-sector.definition'
import { SelectOption } from '../../../../abacus/interfaces/select-option.interface'
import { BudgetAvailability } from '../../../../common/enums/policy-initiative/budget-availability.enum'

// Build the select options for the start year and end year fields.
const startYearFrom: number = 1956
const startYearTo: number = moment().add(5, 'year').year()
const endYearFrom: number = 2005
const endYearTo: number = moment().add(30, 'year').year()

export const getStep1Fields = (
  context: PolicyInitiativeCreateEditComponent
): Field[] => {
  return [
    {
      id: 'category',
      label: 'Category',
      helpText:
        'Please indicate the level of government responsible for the initiative',
      placeholder: 'Enter the name...',
      property: 'category',
      className: 'is-12',
      inputType: InputType.Radio,
      selectOptions: [
        {
          label: 'Intergovernmental or supranational',
          subLabel:
            'This includes AI initiatives from intergovernmental organisations (e.g. OECD, United Nations) and supranational organisations (e.g. European Union, Arab League)',
          value: InitiativeCategory.Intergovernmental
        },
        {
          label: 'National',
          subLabel:
            'This includes AI initiatives from national countries or territories, as well as cross-border initiatives conducted jointly by multiple countries or territories',
          value: 'other'
        }
      ],
      onChange: async (newValue: InitiativeCategory) => {
        if (newValue === InitiativeCategory.Intergovernmental) {
          showInterGovernmentalForm(context)
        } else {
          await showNationalForm(context)
        }
      },
      required: true
    },
    {
      id: 'subCategory',
      label: 'Sub-category',
      placeholder: 'Enter the name...',
      property: 'subCategory',
      className: 'is-12',
      hidden: true,
      inputType: InputType.Radio,
      required: false,
      selectOptions: [
        {
          label: 'National AI Strategy',
          value: InitiativeCategory.NationalStrategy
        },
        {
          label: 'AU Governance Bodies or Mechanisms',
          subLabel:
            'Governance and coordination bodies, offices and processes; advisory bodies; monitoring mechanisms; public or expert consultations',
          value: InitiativeCategory.NationalGovernance
        },
        {
          label:
            'AI Policy Initiatives, regulations, guideline, standards and programmes or projects',
          subLabel:
            'Laws, regulations, public policies or strategies, strategies on specific topics or in adjacent fields (e.g. compute capacity or data strategies)',
          value: InitiativeCategory.NationalGeneral
        }
      ],
      onChange: (newValue: InitiativeCategory) => {
        if (newValue === InitiativeCategory.NationalGeneral) {
          showNationalGeneralForm(context)
        } else if (newValue === InitiativeCategory.NationalGovernance) {
          showNationalGovernanceForm(context)
        } else if (newValue === InitiativeCategory.NationalStrategy) {
          showNationalStrategyForm(context)
        }
      }
    },
    {
      id: 'intergovernmentalOrganisation',
      label: 'Which organisation is linked the policy initiative?',
      property: 'intergovernmentalOrganisationId',
      className: 'is-12 tmp-is-background-success',
      inputType: InputType.Select,
      hidden: true,
      selectOptions: () =>
        context.componentResourceService.listSelectOptions(
          'intergovernmental-organisations'
        )
    },
    {
      id: 'nationalOrganisation',
      label: 'Which country is linked the policy initiative?',
      property: 'nationalOrganisationId',
      className: 'is-12 tmp-is-background-multiple',
      inputType: InputType.Select,
      hidden: true,
      selectOptions: () =>
        context.componentResourceService.listSelectOptions(
          'national-organisations'
        )
    },
    {
      id: 'englishName',
      label: 'Name in english',
      helpText:
        'Please enter the name of the initiative in English, including the acronym if relevant',
      placeholder: 'Enter the name in English...',
      property: 'englishName',
      className: 'is-12',
      inputType: InputType.Text,
      required: true,
      hidden: true
    },
    {
      id: 'originalName',
      label: 'Name in original language',
      helpText:
        'If the name of the initiative if not originally in English, please enter the name in its original language',
      placeholder: 'Enter the name in English...',
      property: 'originalName',
      className: 'is-12',
      inputType: InputType.Text,
      required: true,
      hidden: true
    },
    {
      id: 'status',
      label: 'Status of initiative',
      property: 'status',
      className: 'is-12',
      inputType: InputType.Select,
      selectOptions: Object.entries(InitiativeStatus).map(([key, value]) => ({
        value: InitiativeStatus[key],
        label: value
      })),
      required: true,
      hidden: true
    },
    {
      id: 'responsibleOrganisation',
      label: 'Name of responsible organisation (in English)',
      property: 'responsibleOrganisation',
      className: 'is-12 tmp-is-background-multiple',
      inputType: InputType.Text,
      hidden: true
    },
    {
      id: 'responsibleOrganisationSDLocation',
      label:
        'Who has primary responsibility for developing the national AI strategy?',
      className: 'is-12 tmp-is-background-warning',
      inputType: InputType.Select,
      selectOptions: Object.keys(ResponsibleOrganisationLocation).map(
        (key) => ({
          value: ResponsibleOrganisationLocation[key],
          label: ResponsibleOrganisationLocation[key]
        })
      ),
      property: 'responsibleOrganisationSDLocation',
      hidden: true
    },
    {
      id: 'responsibleOrganisationSILocation',
      label:
        'Who has primary responsibility to implementing the national strategy?',
      className: 'is-12 tmp-is-background-warning',
      inputType: InputType.Select,
      selectOptions: Object.keys(ResponsibleOrganisationLocation).map(
        (key) => ({
          value: ResponsibleOrganisationLocation[key],
          label: ResponsibleOrganisationLocation[key]
        })
      ),
      property: 'responsibleOrganisationSILocation',
      hidden: true
    },
    {
      id: 'responsibleOrganisationSI',
      label:
        'Name of responsible organisation for strategy implementation (in English)',
      className: 'is-12 tmp-is-background-warning',
      inputType: InputType.Text,
      property: 'responsibleOrganisationSI',
      hidden: true
    },
    {
      id: 'extentBinding',
      label: 'Extent binding',
      helpText:
        'Please select which of the items best matches the nature of the initiative.',
      className: 'is-12 tmp-is-background-multiple',
      inputType: InputType.Select,
      selectOptions: [
        {
          label: 'Binding',
          value: 'Binding'
        },
        {
          label: 'Non-binding',
          value: 'Non-binding'
        }
      ],
      property: 'extentBinding',
      hidden: true
    },
    {
      id: 'joint',
      label: 'Joint initiative',
      helpText:
        'Is this initiative conducted jointly with one or more other countries or intergovernmental supranational organisations?',
      className: 'is-12 tmp-is-background-multiple',
      inputType: InputType.Checkbox,
      property: 'joint',
      hidden: true
    },
    {
      id: 'jointCountries',
      label: 'Joint countries or organisations',
      helpText:
        'Please add each country or intergovernmental or supranational organisation that is part of the joint initiative.',
      className: 'is-12 tmp-is-background-multiple',
      inputType: InputType.MultiSearch,
      searchResources: [nationalOrganisationDefinition],
      properties: {
        nationalOrganisationIds: 'jointCountryIds'
      },
      hidden: true
    },
    {
      id: 'participatingCountries',
      label: 'Participating countries',
      helpText:
        'Please add each country, jurisdiction and/or other organisation (e.g., other intergovernmental or supranational organisations) that is part of the initiative (e.g., adherents, signatories)',
      searchResources: [nationalOrganisationDefinition],
      className: 'is-12 tmp-is-background-success',
      inputType: InputType.MultiSearch,
      filter: intergovernmentalOrganisationDefinition,
      properties: {
        nationalOrganisationIds: 'participatingCountryIds'
      },
      hidden: true
    },
    {
      id: 'participatingOrganisations',
      label: 'Participating Organisations',
      helpText:
        'Please add each organisation that is part of the initiative (e.g., other intergovernmental or supranational organisations), if any.',
      className: 'is-12 tmp-is-background-success',
      searchResources: [intergovernmentalOrganisationDefinition],
      inputType: InputType.MultiSearch,
      properties: {
        intergovernmentalOrganisationIds: 'participatingOrganisationIds'
      },
      hidden: true
    },
    {
      id: 'startYear',
      label: 'Start year',
      property: 'startYear',
      helpText:
        'Please enter the anticipated start date of Proposed initiatives, or the actual start date of Active and Complete initiatives.',
      className: 'is-12',
      inputType: InputType.Select,
      selectOptions: Array.from(
        { length: startYearTo - startYearFrom + 1 },
        (_, index) => startYearFrom + index
      ).map((year: number) => ({
        value: year,
        label: year.toString()
      })),
      hidden: true,
      required: true
    },
    {
      id: 'endYear',
      label: 'End year',
      property: 'endYear',
      helpText:
        'Please enter the anticipated end date of the initiative (if there is one), or if the initiative has already concluded, the actual end date.',
      className: 'is-12',
      inputType: InputType.Select,
      selectOptions: [{ label: 'Not applicable', value: 0 }].concat(
        Array.from(
          { length: endYearTo - endYearFrom + 1 },
          (_, index) => endYearFrom + index
        ).map((year: number) => ({
          value: year,
          label: year.toString()
        }))
      ),
      hidden: true,
      required: true
    },
    {
      id: 'initiativeType',
      label: 'Initiative Type',
      helpText:
        'Please indicate which type of initiative best fits the one you are entering.',
      className: 'is-12 tmp-is-background-multiple',
      inputType: InputType.Select,
      selectOptions: () =>
        context.componentResourceService
          .listSelectOptions('taxonomies/initiative-types')
          .then((options: SelectOption[]) =>
            options.map((option) => ({
              label: option.label,
              value: option.value.toString()
            }))
          ),
      property: 'initiativeTypeId',
      hidden: true
    },
    {
      id: 'otherInitiativeType',
      label: 'Value-based initiative type',
      helpText:
        'If appropriate, please indicate an additional type of initiative that best fits the one you are entering',
      className: 'is-12 tmp-is-background-danger',
      hidden: true,
      property: 'otherInitiativeType',
      inputType: InputType.Text
    },
    {
      id: 'trustworthyAIRelation',
      label:
        'Do the indicated item(s) have any mandate/objectives related to trustworthy AI? If so, please specify',
      className: 'is-12 tmp-is-background-info',
      inputType: InputType.Textarea,
      property: 'trustworthyAIRelation',
      hidden: true
    },
    {
      id: 'website',
      label: 'Website',
      property: 'website',
      className: 'is-12',
      inputType: InputType.Text,
      hidden: true
    },
    {
      id: 'actionPlan',
      label: 'Action plan and/or roadmap',
      helpText:
        'If this initiative has an action plan or roadmap, please add the hyperlink here.',
      className: 'is-12 tmp-is-background-multiple',
      inputType: InputType.Text,
      property: 'actionPlan',
      hidden: true
    },
    {
      id: 'budgetAvailable',
      property: 'budgetAvailable',
      label: 'Budget information available?',
      helpText:
        'Please add each country or intergovernmental or supranational organisation that is part of the joint initiative.',
      selectOptions: Object.entries(BudgetAvailability).map(([key, value]) => ({
        value: BudgetAvailability[key],
        label: value
      })),
      className: 'is-12 tmp-is-background-warning',
      inputType: InputType.Select,
      hidden: true
    },
    {
      id: 'budget',
      label: 'Estimated budget expenditure range per year in Euros (EUR)',
      helpText:
        'Please enter the estimated annual expenditure expected. For example, if a national AI strategy is budgeted for EUR 5 billion over the next five years, please enter the yearly figure of “1 000 000 000”). You may wish to use a currency converter to convert your national currency into Euros.',
      className: 'is-12 tmp-is-background-warning',
      inputType: InputType.Number,
      property: 'budget',
      hidden: true
    },
    {
      id: 'engagementMechanism',
      label:
        'To what extent do you have mechanisms or other efforts to involve stakeholders in strategy design, implementation and monitoring?',
      helpText: 'Please select all that apply.',
      className: 'is-12 tmp-is-background-warning',
      inputType: InputType.MultiCheckbox,
      selectOptions: () =>
        context.componentResourceService.listSelectOptions(
          'taxonomies/engagement-mechanisms',
          { orderBy: 'id' }
        ),
      property: 'engagementMechanismIds',
      hidden: true
    },
    {
      id: 'engagementDescription',
      label:
        'Please describe these mechanisms or other efforts for stakeholder engagement',
      className: 'is-12 tmp-is-background-warning',
      inputType: InputType.Text,
      property: 'engagementDescription',
      hidden: true
    },
    {
      id: 'intergovernmentalCoordination',
      label:
        'To what extent are there mechanisms that ensure inter-governmental coordination?',
      className: 'is-12 tmp-is-background-warning',
      inputType: InputType.Text,
      property: 'intergovernmentalCoordination',
      hidden: true
    },
    {
      id: 'hasMonitoringMechanism',
      label: 'Is there a mechanism to monitor implementation?',
      className: 'is-12 tmp-is-background-multiple',
      inputType: InputType.Checkbox,
      property: 'hasMonitoringMechanism',
      hidden: true
    },
    {
      id: 'monitoringMechanismDescription',
      label: 'Please describe the monitoring mechanism',
      className: 'is-12 tmp-is-background-warning',
      inputType: InputType.Text,
      property: 'monitoringMechanismDescription',
      hidden: true
    },
    {
      id: 'description',
      label: 'Short description',
      property: 'description',
      className: 'is-12',
      inputType: InputType.Textarea,
      htmlExample: `
      <p>This is an example of an HTML content that can be used to provide a short description of the initiative.</p>
      <p>It can include multiple paragraphs, lists, links, and other HTML elements.</p>
      `,
      max: 500,
      hidden: true
    },
    {
      id: 'overview',
      label: 'Overview',
      property: 'overview',
      htmlHelpText: `
      <div class="content is-small">
        <p>
          The initiative overview is an optional expanded overview of the initiative and its intended or demonstrated outcomes. This could potentially be the same text as an abstract, executive summary, fact sheet, or one-pager about the initiative.
      </p>
      <p>
          Going beyond the short and simple description, this should provide an expanded set of information for the initiative at a high level. In approximately 3-4 paragraphs (maximum 3,000 characters), please share additional information about the initiative, such as:
      </p>
      <ul>
          <li><strong>What</strong> issue does the initiative seek to resolve or what opportunity does it seek to leverage?</li>
          <li><strong>What</strong> are the objectives of the initiative?</li>
          <li><strong>How</strong> is the initiative expected to evolve in the future? For example, how will it be implemented and institutionalised? How could it scale more broadly?</li>
          <li><strong>How</strong> has the initiative <strong>evolved</strong> over time? (especially relevant for periodic updates)</li>
      </ul>
      </div>
      `,
      htmlExample: `
      <p>This is an example of an HTML content that can be used to provide an overview of the initiative.</p>
      <p>It can include multiple paragraphs, lists, links, and other HTML elements.</p>
      `,
      className: 'is-12',
      inputType: InputType.RichText,
      validators: [Validators.maxLength(3000)],
      max: 3000,
      hidden: true
    },
    {
      id: 'trustworthyAIMechanismDescription',
      label:
        'To what extent does the national AI strategy contain measures that enable the development and deployment of trustworthy AI?',
      className: 'is-12 tmp-is-background-warning',
      inputType: InputType.Text,
      property: 'trustworthyAIMechanismDescription',
      hidden: true
    },
    {
      id: 'aiPrinciples',
      label: 'Relevant OECD AI Principle(s) addressed',
      htmlHelpText: `<p>The <a href="https://oecd.ai/en/ai-principles" target="_blank">OECD AI Principles</a> feature five value-based principles, and five recommendations for policymakers, as shown on the table below.
  Please indicate <strong>at least one</strong> of the items below that is most relevant to this initiative.</p>`,
      placeholder: 'Enter the checkboxes...',
      property: 'principleIds',
      className: 'is-12 tmp-is-background-multiple',
      inputType: InputType.MultiCheckbox,
      selectOptions: () =>
        context.componentResourceService.listSelectOptions(
          'taxonomies/ai-principles',
          { orderBy: 'name' }
        ),
      hidden: true
    },
    {
      id: 'targetSectors',
      label: 'Relevant sectors covered by this initiative',
      className: 'is-12',
      inputType: InputType.MultiSearch,
      searchResources: [targetSectorDefinition],
      properties: {
        targetSectorIds: 'targetSectorIds'
      },
      hidden: true
    },
    {
      id: 'tags',
      label: 'AI tags',
      className: 'is-12',
      helpText:
        'Please add any tags that are highly relevant to this initiative',
      inputType: InputType.MultiSearch,
      searchResources: [aiTagDefinition],
      properties: {
        aITagIds: 'tagIds'
      },
      hidden: true
    }
  ]
}
