import { Field } from '../../../../abacus/interfaces/field.interface'
import { PolicyInitiativeCreateEditComponent } from '../policy-initiative-create-edit.component'

const fieldsThatDependOnEvaluationStatus: string[] = [
  'evaluationBy',
  'isEvaluationResultsPubliclyAvailable',
  'evaluationUrl',
  'otherEvaluationUrl',
  'evaluationFiles',
  'evaluationDescription'
]

export const showIsEvaluatedFields = (
  context: PolicyInitiativeCreateEditComponent
) => {
  context.showFields(fieldsThatDependOnEvaluationStatus)
}

export const hideIsEvaluatedFields = async (
  context: PolicyInitiativeCreateEditComponent
) => {
  await context.hideAndEmptyFields(fieldsThatDependOnEvaluationStatus)
}

export const setStep3ConditionalForm = async (
  context: PolicyInitiativeCreateEditComponent,
  item: any
) => {
  const isEvaluatedField: Field = context.fields.find(
    (field) => field.property === 'isEvaluated'
  )

  if (item.isEvaluated) {
    showIsEvaluatedFields(context)
  } else {
    await hideIsEvaluatedFields(context)
  }

  // Convert boolean to string (for HTML select input).
  context.setFieldValue(isEvaluatedField, item.isEvaluated ? 'true' : 'false')
}
