<ul class="stepper">
  <li
    *ngFor="let i of stepsArray"
    [ngClass]="{
      current: i === currentStep,
      completed: i < currentStep,
      'not-started': i > currentStep
    }"
    (click)="onStepClicked(i)"
  >
    <span class="step-circle">
      <span *ngIf="i >= currentStep">{{ i }}</span>
      <i *ngIf="i < currentStep" class="icon icon-check2"></i>
    </span>
    <span class="step-label">Step {{ i }}</span>
  </li>
</ul>
