<div [formGroup]="form">
  <div class="control">
    <label for="text-input-{{ uniqueId }}"
      >{{ label }}<span class="has-text-danger" *ngIf="required">*</span></label
    >
    <p class="help mb-1" *ngIf="helpText">{{ helpText }}</p>
    <input
      type="text"
      class="input form-control"
      [ngClass]="{ 'is-danger': showErrors && form.invalid }"
      [placeholder]="placeholder || label + '...'"
      formControlName="text"
      id="text-input-{{ uniqueId }}"
      (keyup)="onKeyup($event.target.value)"
    />
  </div>
</div>
