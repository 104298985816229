import { YieldType } from '../../abacus/enums/yield-type.enum'
import { Yield } from '../../abacus/interfaces/yield.interface'

export const policyInitiativeYields: Yield[] = [
  {
    label: 'Name',
    property: 'englishName'
  },
  {
    label: 'Original Name',
    property: 'originalName'
  },
  {
    label: 'Creation date',
    property: 'createdAt',
    type: YieldType.Date
  }
]
