import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms'

import { CaseInput } from '../../../interfaces/case-input.interface'
import { environment } from '../../../../../environments/environment'
import { UploadService } from '../../../services/upload.service'
import { FlashMessageService } from '../../../services/flash-message.service'

@Component({
  selector: 'case-multi-file-input',
  templateUrl: './multi-file-input.component.html',
  styleUrls: ['./multi-file-input.component.scss']
})
export class MultiFileInputComponent implements OnChanges, CaseInput {
  @Input() label: string
  @Input() initialValue: {
    name: string
    path: string
    year: string
    id?: number
  }[]
  @Input() placeholder: string
  @Input() helpText: string
  @Input() resourceName: string
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string
  @Input() max: number
  @Input() askFileYear: boolean

  form: FormGroup

  @Output() valueChanged: EventEmitter<
    { name: string; year: string; path: string }[]
  > = new EventEmitter()

  @ViewChild('fileInput', { static: false }) fileInputEl: ElementRef

  storagePath: string = environment.storagePath
  files: { name: string; year: string; path: string }[] = []
  showModal: boolean
  loading: boolean

  constructor(
    private formBuilder: FormBuilder,
    private uploadService: UploadService,
    private flashMessageService: FlashMessageService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // Prevent value from being reset if showErrors changes.
    if (
      Object.keys(changes).length === 1 &&
      Object.keys(changes)[0] === 'showErrors'
    ) {
      return
    }

    if (this.initialValue?.length) {
      this.files = this.initialValue
    }
  }

  openForm() {
    this.showModal = true
    this.form = this.formBuilder.group({
      name: '',
      year: '',
      path: ['', Validators.required]
    })
  }

  closeForm() {
    this.showModal = false
    this.form.reset()
  }

  saveForm() {
    this.files.push({
      name: this.form.value.name || this.form.value.path,
      year: this.form.value.year || '',
      path: this.form.value.path
    })
    this.valueChanged.emit(this.files)
    this.closeForm()
  }

  // Upload file and update value.
  addFile() {
    this.loading = true
    this.uploadService
      .uploadFile(
        this.resourceName,
        this.fileInputEl.nativeElement.files.item(0)
      )
      .subscribe(
        (res: { path: string }) => {
          this.loading = false

          this.form.patchValue({
            path: res.path
          })

          this.valueChanged.emit(this.files)
        },
        (err) => {
          this.loading = false
          this.flashMessageService.error(
            'There was an error uploading your file.'
          )
        }
      )
  }

  removeFile(index: number) {
    this.files.splice(index, 1)

    setTimeout(() => {
      this.fileInputEl.nativeElement.focus(), 0
    })

    this.valueChanged.emit(this.files)
  }
}
