import { Validators } from '@angular/forms'
import { Field } from '../../../../abacus/interfaces/field.interface'
import { PolicyInitiativeCreateEditComponent } from '../policy-initiative-create-edit.component'
import { InitiativeCategory } from '../../../../common/enums/policy-initiative/initiative-category.enum'

const generalFieldIds: string[] = [
  'englishName',
  'originalName',
  'status',
  'startYear',
  'endYear',
  'website',
  'description',
  'overview',
  'targetSectors',
  'tags'
]

const intergovernmentalFields: string[] = [
  'intergovernmentalOrganisation',
  'participatingCountries',
  'participatingOrganisations',
  'extentBinding',
  'actionPlan',
  'initiativeType',
  'aiPrinciples'
]

const nationalStrategyFields: string[] = [
  'nationalOrganisation',
  'responsibleOrganisationSDLocation',
  'responsibleOrganisation',
  'responsibleOrganisationSILocation',
  'responsibleOrganisationSI',
  'joint',
  'jointCountries',
  'actionPlan',
  'budgetAvailable',
  'budget',
  'engagementMechanism',
  'engagementDescription',
  'intergovernmentalCoordination',
  'hasMonitoringMechanism',
  'monitoringMechanismDescription',
  'trustworthyAIMechanismDescription',
  'aiPrinciples'
]
const nationalGovernanceFields: string[] = [
  'aiPrinciples',
  'nationalOrganisation',
  'responsibleOrganisation',
  'joint',
  'jointCountries',
  'initiativeType',
  'trustworthyAIRelation'
]
const nationalGeneralFields: string[] = [
  'responsibleOrganisation',
  'extentBinding',
  'joint',
  'jointCountries',
  'initiativeType',
  'nationalOrganisation',
  'hasMonitoringMechanism',
  'otherInitiativeType'
]

// Conditional help text for the description field.
const descriptionHtmlHelpTextIfInterGovernmental: string = `
<div class="content is-small">  
    <p>
      This explanation should describe the AI initiative in about three short and simple sentences. This is one of the most important fields. The explanation should:
    </p>
    <ul>
        <li>Use clear and succinct language</li>
        <li>Be able to be understood by someone who is not familiar with the sector or initiative</li>
        <li>Set the context for policymakers and other users who are reading about the initiative</li>
        <li>Use simple, not sector-specific terminology (avoiding idioms or "buzz" words)</li>
    </ul>
    <p>The explanation should describe:</p>
    <ul>
        <li>What are the objectives of the strategy?</li>
        <li>What are the pillars of the strategy and what sectors does it focus on (e.g., public sector, health, agriculture)?</li>
    </ul>
    </div>
`
const descriptionHtmlHelpTextIfNational: string = `
   <p>
        This explanation should describe the AI initiative in about three short and simple sentences. This is one of the most important fields. The explanation should:
    </p>
    <ul>
        <li>Use clear and succinct language</li>
        <li>Be able to be understood by someone who is not familiar with the sector or initiative</li>
        <li>Set the context for policymakers and other users who are reading about the initiative</li>
        <li>Use simple, not sector-specific terminology (avoiding idioms or "buzz" words)</li>
    </ul>
    <p>The explanation should describe:</p>
    <ul>
        <li>What the initiative is and who may benefit</li>
        <li>Why the initiative was developed or the problem/opportunity being addressed</li>
    </ul>
`

const htmlTooltipIfInterGovernmental: string = `
  <h4>Legally Binding and Non-Legally Binding Instruments</h4>
  <br />  
  <p>Legally binding: Directly binding on contracting parties either upon signature or upon ratification depending on the provisions of the instrument (e.g. treaties and conventions, agreements, decisions, and other forms of prescriptive instruments).</p>
  <p>Non-legally binding: By nature or wording, these are not intended to be legally binding.</p>
  <ul>
  <li>• Where States transpose these instruments (or some of their provisions) into domestic legislation or recognise them in international legally binding instruments such as treaties, the relevant instruments or provisions acquire legally binding value (e.g. Mutual Recognition Agreements, model laws, legislative guides).</li>
  <li>• Statements of intent or guidance which are aimed specifically at encouraging certain behaviours and pooling experiences, or framing priorities and expressing commitments (e.g. declarations, guidelines, best practices).</li>
  </ul>
  `

export const showInterGovernmentalForm = (
  context: PolicyInitiativeCreateEditComponent
) => {
  const descriptionField: Field = context.getFieldById('description')
  const overviewField: Field = context.getFieldById('overview')

  context.showFields([...generalFieldIds, ...intergovernmentalFields])
  context.hideAndEmptyFields(
    [
      'subCategory',
      ...nationalGeneralFields,
      ...nationalGovernanceFields,
      ...nationalStrategyFields
    ].filter((fieldId) => !intergovernmentalFields.includes(fieldId))
  )

  descriptionField.htmlHelpText = descriptionHtmlHelpTextIfInterGovernmental
  descriptionField.htmlTooltip = htmlTooltipIfInterGovernmental
  overviewField.htmlTooltip = htmlTooltipIfInterGovernmental
}

export const showNationalForm = async (
  context: PolicyInitiativeCreateEditComponent
) => {
  const categoryField: Field = context.getFieldById('category')
  const subCategoryField: Field = context.getFieldById('subCategory')
  const descriptionField: Field = context.getFieldById('description')
  const overviewField: Field = context.getFieldById('overview')

  context.setFieldValue(categoryField, 'other')
  context.showFields(['subCategory'])

  subCategoryField.validators = [Validators.required]
  await context.resetFieldFormControls(subCategoryField)

  descriptionField.htmlHelpText = descriptionHtmlHelpTextIfNational
  descriptionField.htmlTooltip = ''
  overviewField.htmlTooltip = ''
}

export const showNationalGeneralForm = (
  context: PolicyInitiativeCreateEditComponent
) => {
  const subCategoryField: Field = context.getFieldById('subCategory')

  context.setFieldValue(subCategoryField, InitiativeCategory.NationalGeneral)
  context.showFields([...generalFieldIds, ...nationalGeneralFields])
  context.hideAndEmptyFields(
    [
      ...intergovernmentalFields,
      ...nationalGovernanceFields,
      ...nationalStrategyFields
    ].filter((fieldId) => !nationalGeneralFields.includes(fieldId))
  )
}

export const showNationalGovernanceForm = (
  context: PolicyInitiativeCreateEditComponent
) => {
  const subCategoryField: Field = context.getFieldById('subCategory')

  context.setFieldValue(subCategoryField, InitiativeCategory.NationalGovernance)

  context.showFields([...generalFieldIds, ...nationalGovernanceFields])
  context.hideAndEmptyFields(
    [
      ...intergovernmentalFields,
      ...nationalGeneralFields,
      ...nationalStrategyFields
    ].filter((fieldId) => !nationalGovernanceFields.includes(fieldId))
  )
}

export const showNationalStrategyForm = (
  context: PolicyInitiativeCreateEditComponent
) => {
  const subCategoryField: Field = context.getFieldById('subCategory')

  context.setFieldValue(subCategoryField, InitiativeCategory.NationalStrategy)
  context.showFields([...generalFieldIds, ...nationalStrategyFields])
  context.hideAndEmptyFields(
    [
      ...intergovernmentalFields,
      ...nationalGeneralFields,
      ...nationalGovernanceFields
    ].filter((fieldId) => !nationalStrategyFields.includes(fieldId))
  )
}

export const setStep1ConditionalForm = (
  context: PolicyInitiativeCreateEditComponent,
  item: any
) => {
  if (item.category === InitiativeCategory.Intergovernmental) {
    showInterGovernmentalForm(context)
  } else {
    showNationalForm(context)

    if (item.category === InitiativeCategory.NationalStrategy) {
      showNationalStrategyForm(context)
    } else if (item.category === InitiativeCategory.NationalGovernance) {
      showNationalGovernanceForm(context)
    } else {
      showNationalGeneralForm(context)
    }
  }
}
